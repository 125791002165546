import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { GoogleOAuthProvider } from "@react-oauth/google";

// Contexts
import { AuthProvider } from "./context/AuthContext";

import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
} from "react-router-dom";

import Home from "./pages/Home";
import Streak from "./pages/Streak";
import Error from "./pages/Error";

import Shelves from "./pages/Shelves";
import ShelfCategory from "./pages/ShelfCategory";

import Discover from "./pages/Discover";
import Search from "./pages/Search";
import Profile from "./pages/Profile";
import Tester from "./pages/Tester";
import Settings from "./pages/Settings";
import BookView from "./pages/BookView";
import BookRequestPage from "./pages/BookRequestPage";
import TermsOfService from "./pages/Legal/TermsOfService";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";

import Landing from "./pages/Landing";

import LoginPage from "./pages/Authentication/LoginPage";
import SignupPage from "./pages/Authentication/SignupPage";
import StatusPage from "./pages/StatusPage";

import { Toaster } from "./components/ui/sonner";

import ChallengePage from "./pages/ChallengePage";
import ViewChallengesPage from "./pages/ViewChallengesPage";

import BookClubView from "./pages/BookClub/BookClubView";
import CreateBookClub from "./pages/BookClub/CreateBookClub";
import BookClubListMenu from "./pages/BookClub/BookClubListMenu";
import BookClubJoinPage from "./pages/BookClub/BookClubJoinPage";
import BookClubManageClub from "./pages/BookClub/BookClubManageClub";

import ViewReviewPage from "./pages/Review/ViewReviewPage";
import BookQuiz from "./pages/BookQuiz";
import CreateBookQuizPage from "./pages/Quiz/CreateBookQuizPage";

import WordSearch from "./pages/games/WordSearch";
import SpineStacker from "./pages/games/SpineStacker";
import CoverPuzzle from "./pages/games/CoverPuzzle";
import GamesList from "./pages/games/GamesList";

import ReviewReports from "./pages/Admin/ReviewReports";
import Admin from "./pages/Admin/Admin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <Error />,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/streak",
    element: <Streak />,
    errorElement: <Error />,
  },
  {
    path: "/bookshelves",
    element: <Shelves />,
    errorElement: <Error />,
  },
  {
    path: "/bookshelf/:category",
    element: <ShelfCategory />,
    errorElement: <Error />,
  },
  /*{
    path: "/discover",
    element: <Discover />,
    errorElement: <Error />
  },*/
  {
    path: "/book",
    element: <BookView />,
    errorElement: <Error />,
  },
  {
    path: "/status",
    element: <StatusPage />,
    errorElement: <Error />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <Error />,
  },
  {
    path: "/search",
    element: <Search />,
    errorElement: <Error />,
  },
  {
    path: "/profile",
    element: <Profile />,
    errorElement: <Error />,
  },
  /*{
    path: "/tester",
    element: <Tester />,
    errorElement: <Error />
  },*/
  {
    path: "/settings",
    element: <Settings />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub/create",
    element: <CreateBookClub />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub/view",
    element: <BookClubView />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub",
    element: <BookClubListMenu />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub/join",
    element: <BookClubJoinPage />,
    errorElement: <Error />,
  },
  {
    path: "/bookclub/manage",
    element: <BookClubManageClub />,
    errorElement: <Error />,
  },
  {
    path: "/bookrequest",
    element: <BookRequestPage />,
    errorElement: <Error />,
  },
  {
    path: "/legal/terms-of-service",
    element: <TermsOfService />,
    errorElement: <Error />,
  },
  {
    path: "/legal/privacy",
    element: <PrivacyPolicy />,
    errorElement: <Error />,
  },
  {
    path: "/challenge/create",
    element: <ChallengePage />,
    errorElement: <Error />,
  },
  {
    path: "/challenge",
    element: <ViewChallengesPage />,
    errorElement: <Error />,
  },
  {
    path: "/review",
    element: <ViewReviewPage />,
    errorElement: <Error />,
  },
  {
    path: "/games/wordsearch",
    element: <WordSearch />,
    errorElement: <Error />,
  },
  {
    path: "/games/spinestacker",
    element: <SpineStacker />,
    errorElement: <Error />,
  },
  {
    path: "/games/coverpuzzle",
    element: <CoverPuzzle />,
    errorElement: <Error />,
  },
  {
    path: "/games",
    element: <GamesList />,
    errorElement: <Error />,
  },
  {
    path: "/admin/reviewReports",
    element: <ReviewReports />,
    errorElement: <Error />,
  },
  {
    path: "/admin",
    element: <Admin />,
    errorElement: <Error />,
  },
  /*{
    path: "/bookquiz",
    element: <BookQuiz />,
    errorElement: <Error />
  },*/
  /*{
    path: "/bookquiz/create",
    element: <CreateBookQuizPage />,
    errorElement: <Error />
  },*/
  {
    path: "*",
    element: <Error />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="bg-slate-900 h-full text-slate-50 dark">
    <GoogleOAuthProvider clientId="337489570830-nclgj46aqpipl7emqics0p8j0lcgej9g.apps.googleusercontent.com">
      <AuthProvider>
        <Toaster richColors expand={true} />
        <RouterProvider router={router} />
      </AuthProvider>
    </GoogleOAuthProvider>
  </div>,
);
