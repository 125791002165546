import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import catSplash from "../assets/catSplash.PNG"

import { Link } from "react-router-dom"

function Error() {
    return (
        <div>
            <Navbar showAccountPrompt={true} />

            <div className="w-[60vw] ml-auto mr-auto mt-[5rem] mb-[7rem] rounded-xl bg-slate-950/50 flex p-4 flex-row items-center">
                <img src={catSplash} alt="catSplash" className="w-[20vw] rounded-lg opacity-80" />

                <span className="w-full flex flex-col justify-center items-center text-[1.6rem] font-normal">
                    <span>
                        Dang! Something went wrong!
                    </span>

                    <Link to="/home" className="mt-4 gap-2 w-fit flex flex-row items-center bg-slate-800/50 hover:bg-slate-800/90 text-[1.2rem] cursor-pointer rounded-lg px-4 py-2 justify-center">
                        <span>Go Home</span>
                        <span className="material-symbols-outlined transition group-hover:translate-x-2">arrow_forward</span>
                    </Link>
                </span>
            </div>

            <Footer />
        </div>
    )
}

export default Error
