import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../context/AuthContext";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { toast } from "sonner";
import { Link, useLocation } from "react-router-dom";

import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
  } from "../../components/ui/hover-card"

export default function BookClubJoinPage() {
    const { authenticated, login, logout, signup } = useAuth();
    const location = useLocation();

    // get ?code= from url
    let code = undefined;

    try {
        const search = location.search;
        const params = new URLSearchParams(search);
        code = params.get("code");
    } catch (error) {
        console.log(error);
    }

    const [inputValue, setInputValue] = useState("");

    //if (code !== undefined && code !== null) {
    //    joinBookClub();
    //}

    async function joinBookClub() {
        try {
            const inviteCode = inputValue;

            const response = await fetch(`https://nerded.io/api/bookclub/join`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    invite_code: inviteCode,
                })
            });

            const data = await response.json();


            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                if (data.error === "User is already in book club") {
                    window.location.href = `/bookclub/view?id=${data.id}`;
                }

                toast.error(data.error);
                return;
            }

            toast.success(data.data);

            setInputValue("");

            // redirect to the book club
            window.location.href = `/bookclub/view?id=${data.id}`;
        } catch (error) {
            console.log(error);
            toast.error("An error occurred. Please try again later.");
        }
    }

    useEffect(() => {
        if (code !== undefined && code !== null) {
            setInputValue(code);
            joinBookClub();
        }
    }, [code]);

    // Handle input changes
    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    function goBack() {
        window.history.back();
    }

    return (
        <div className="grid-new">
            <Navbar />

            <div className="flex flex-row gap-2 ml-[2rem] cursor-pointer">
                <div onClick={goBack} className="rounded-lg py-2 px-3 bg-slate-800 hover:bg-slate-700 transition flex flex-row items-center">
                    <div className="text-slate-300 text-[1.1rem] flex flex-row items-center">
                        <span className="material-symbols-outlined items-center">arrow_back</span>
                    </div>
                </div>
            </div>

            <div className="mt-4 mb-[4rem]">
                <div className="container mx-auto flex flex-col gap-4 w-fit">
                    <div className="flex flex-row items-center gap-4">
                        <Link to="/bookclub" className="text-slate-300 hover:underline transition">Book Club</Link>
                        <span className="text-slate-400">/</span>
                        <span className="text-slate-400">Join Book Club</span>
                    </div>
                </div>

                <div className="container mx-auto justify-center flex mt-[7rem] mb-[8rem] w-fit gap-3">
                <input
                        value={inputValue}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter Book Club Invite Code"
                        className="w-[30vw] px-4 py-3 rounded-lg bg-slate-900 border border-slate-700 focus:border-slate-600 outline-none"
                    />
                    <div onClick={joinBookClub} className="flex cursor-pointer gap-3 bg-slate-800 rounded-lg w-fit px-6 py-3 hover:bg-slate-700 transition justify-center items-center">
                        Join
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}
