import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import CreateBookClubForm from "../../components/BookClub/CreateBookClubForm";

function CreateBookClub() {
    const { authenticated, login, logout, signup } = useAuth();

    if (!authenticated.authenticated) {
        login();
        return;
    }

    function goBack() {
        window.history.back();
    }

    return (
        <div className="grid-new">
            <Navbar />

            <div className="flex flex-row gap-2 ml-[2rem] cursor-pointer">
                <div onClick={goBack} className="rounded-lg py-2 px-3 bg-slate-800 hover:bg-slate-700 transition flex flex-row items-center">
                    <div className="text-slate-300 text-[1.1rem] flex flex-row items-center">
                        <span className="material-symbols-outlined items-center">arrow_back</span>
                    </div>
                </div>
            </div>

            <div className="ml-auto mr-auto w-fit my-[4rem] mb-[6rem]">
                <CreateBookClubForm />
            </div>

            <Footer />
        </div>
    )
}

export default CreateBookClub
