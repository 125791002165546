import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast } from "sonner";

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

function Streak() {
    const { authenticated, login, logout } = useAuth();

    const Navigate = useNavigate();

    if (!authenticated.authenticated) {
        login();
    }


    const mapColors = {
        "unranked": "text-gray-400",
        "bronze": "text-amber-700",
        "silver": "text-slate-400",
        "gold": "text-amber-500",
        "platinum": "text-slate-500",
        "diamond": "text-blue-300",
        "master": "text-red-600",
        "legend": "text-sky-200",
    }

    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const [streakInfo, setStreakInfo] = useState({
        current_streak: 0,
        longest_streak: 0,
        last_updated: 0,
        date_longest_streak_started: 0,
        date_current_streak_started: 0
    });

    const [levelingData, setLevelingData] = useState({
        level: 0,
        experience: 0,
        experience_to_next_level: 0,
        current_league: "unranked",
        highest_league: "unranked",
        current_rank: 0,
    });

    const [rendered, setRendered] = useState(false);

    useEffect(() => {
        async function getStreakData() {
            await fetch("https://nerded.io/api/streaks/view_user_streak", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.requestStatus === "error") {
                    if (data?.relog) {
                        toast.error("Please login again!");
                        logout();
                    }

                    toast.error(data.error);
                    return;
                }

                setStreakInfo(data.streaks);
                setLevelingData(data.leveling);

                setRendered(true);
            })
        }

        getStreakData();

    }, [authenticated.userId, authenticated.sessionToken]);

    console.log(streakInfo);

    const streakChecks = [];
    const today = new Date();
    const streakStartDate = new Date(streakInfo.date_current_streak_started * 1000);
    const streakDays = streakInfo.current_streak;

    // Calculate the start of the current week (Sunday)
    const currentWeekStart = new Date(today);
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    currentWeekStart.setDate(today.getDate() - dayOfWeek);
    currentWeekStart.setHours(0, 0, 0, 0);

    // Calculate the days from the start of the current week to today
    const daysFromStartOfWeek = dayOfWeek + 1; // Today is included

    // Fill the streakChecks array
    for (let i = 0; i < 7; i++) {
        const checkDate = new Date(currentWeekStart);
        checkDate.setDate(currentWeekStart.getDate() + i);

        if (i < daysFromStartOfWeek) {
            const dayIndexFromStart = i - (daysFromStartOfWeek - streakDays);
            if (dayIndexFromStart >= 0 && dayIndexFromStart < streakDays) {
                streakChecks.push(
                    <div className="text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold text-slate-100" key={i}>
                        <span className="material-symbols-outlined text-[1.6rem] md:text-[2.6rem] lg:text-[2.6rem] xl:text-[2.6rem] 2xl:text-[2.6rem] font-normal text-emerald-500">
                            check_circle
                        </span>
                    </div>
                );
            } else {
                streakChecks.push(
                    <div className="text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold text-slate-100" key={i}>
                        <span className="material-symbols-outlined text-[1.6rem] md:text-[2.6rem] lg:text-[2.6rem] xl:text-[2.6rem] 2xl:text-[2.6rem] font-normal text-gray-400">
                            radio_button_unchecked
                        </span>
                    </div>
                );
            }
        } else {
            streakChecks.push(
                <div className="text-1xl font-semibold text-slate-100" key={i}>
                    <span className="material-symbols-outlined text-[1.6rem] md:text-[2.6rem] lg:text-[2.6rem] xl:text-[2.6rem] 2xl:text-[2.6rem] font-normal text-gray-400">
                        radio_button_unchecked
                    </span>
                </div>
            );
        }
    }

    return (
        <div className="grid-new">
            <Navbar showAccountPrompt={true} />

            {rendered ? <div className="flex flex-col ml-auto mr-auto w-fit gap-[1rem] mt-[1rem] mb-[2rem] justify-center items-center">

                <div className="flex flex-col max-w-[95vw] gap-[1rem] bg-slate-900 py-[1.5rem] px-[1.8rem] my-[4rem] rounded-xl shadow shadow-slate-950 border border-slate-700">

                    <div className='flex flex-row justify-between items-center mb-[1rem]'>
                        <div className="text-2xl font-semibold text-slate-100">
                            Your Streak
                        </div>

                        <div className="flex flex-row items-center w-fit gap-2 px-3 py-2 rounded-lg bg-slate-950/50">
                            <span className="material-symbols-outlined text-[2.1rem] text-emerald-500">
                                local_fire_department
                            </span>

                            <span className="text-[1.2rem]">
                                {streakInfo.current_streak} day streak
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 bg-slate-950/50 px-3 py-2 rounded-lg">
                        <div className="flex flex-row text-sm md:text-lg lg:text-lg xl:text-lg 2xl:text-lg gap-4 items-center w-full justify-between">
                            <span className="w-full flex justify-center">
                                Sun
                            </span>

                            <span className="w-full flex justify-center">
                                Mon
                            </span>

                            <span className="w-full flex justify-center">
                                Tue
                            </span>

                            <span className="w-full flex justify-center">
                                Wed
                            </span>

                            <span className="w-full flex justify-center">
                                Thu
                            </span>

                            <span className="w-full flex justify-center">
                                Fri
                            </span>

                            <span className="w-full flex justify-center">
                                Sat
                            </span>
                        </div>

                        <div className="flex flex-row flex-wrap gap-4 justify-between items-center">
                            {streakChecks}
                        </div>
                    </div>

                    <div className="w-full border-b border-b-slate-800 my-2"></div>

                    <div className="flex flex-row items-center gap-2 px-3 py-2 rounded-lg bg-slate-950/50">
                        <span className={`material-symbols-outlined text-[2.1rem] ${mapColors[levelingData.current_league]}`}>
                            emoji_events
                        </span>

                        <span className={`text-[1.2rem]`}>
                            {
                                levelingData.current_league === "unranked" ? "Unranked" : capitalize(levelingData.current_league) + " League"
                            }
                        </span>

                        <span className="text-slate-400 ml-auto pr-1">
                            Level {levelingData.level} &nbsp;({levelingData.experience} XP)
                        </span>
                    </div>

                    {/*<div className="flex flex-col gap-3 p-3 rounded-lg bg-slate-950/50">
                        <div className="flex flex-row gap-3 items-center rounded-lg">
                            <img src={authenticated.picture} className="rounded-full w-[40px] h-[40px]" />
                            <span className="text-[1rem]">
                                {authenticated.name}
                            </span>

                            <span className="ml-auto text-slate-400">
                                40 XP
                            </span>
                        </div>

                            <span className="rounded bg-slate-950/50 px-2 py-2 flex flex-row gap-1 items-center">
                                <span className="material-symbols-outlined text-[1.5rem] text-slate-400">
                                    workspace_premium
                                </span>
                            </span>
                        </div>
                    </div>*/}
                </div>

                {/*<div className="flex flex-row gap-[1rem] w-[55vw]">
                    <div className="p-3 rounded-xl flex items-center justify-center text-center bg-blue-950 border-[3px] border-blue-600 text-slate-100 w-[45%] h-[150px]">
                        <div className="text-3xl font-semibold flex gap-3 items-center">
                            <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                                workspace_premium
                            </span>

                            Level 1
                        </div>
                    </div>

                    <div className="p-3 rounded-xl flex items-center justify-center text-center bg-blue-950 border-[3px] border-blue-600 text-slate-100 w-[55%] h-[150px]">
                        <div className="text-3xl font-semibold flex gap-3 items-center">
                            <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                                local_fire_department
                            </span>

                            50 day streak
                        </div>
                    </div>
                </div>

                <div className="flex flex-row gap-[1rem] w-[55vw]">

                    <div className="p-3 rounded-xl flex items-center justify-center text-center bg-blue-950 border-[3px] border-blue-600 text-slate-100 w-[55%] h-[150px]">
                        <div className="text-3xl font-semibold flex gap-3 items-center">
                            <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                                emoji_events
                            </span>
                            4 Completed
                        </div>
                    </div>

                    <div className="p-3 rounded-xl flex items-center justify-center text-center bg-blue-950 border-[3px] border-blue-600 text-slate-100 w-[45%] h-[150px]">
                        <div className="text-3xl font-semibold flex gap-3 items-center">
                            <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                                database
                            </span>
                            1,204 Coins
                        </div>
                    </div>

                </div>
            </div>

            <div className="flex flex-row gap-5 justify-center rounded-lg w-fit mt-[3rem] mb-[3rem] ml-auto mr-auto">
                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-yellow-950 border-2 border-yellow-600">
                    <span className="material-symbols-outlined font-semibold text-[2.7rem] text-yellow-500">
                        done
                    </span>
                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>

                <div className="flex p-3 text-3xl rounded-lg w-[5rem] h-[5rem] justify-center items-center bg-blue-950 border-2 border-blue-600">

                </div>
            </div>

            <div className="flex flex-col gap-y-[2rem] mt-[2rem] mb-[3rem]">
                <div className="flex flex-col bg-blue-950 w-[40rem] h-[15rem] ml-auto mr-auto p-4 rounded-lg">
                    <div className="text-2xl">Streaks</div>

                    <div className="flex flex-col gap-2">
                        <div className="">
                            You've logged in daily for the past
                        </div>

                        <div className="p-2 px-3 bg-blue-600 w-fit rounded-full">
                            59 days
                        </div>
                    </div>
                </div>

                <div className="flex flex-col bg-blue-950 w-[40rem] h-[15rem] ml-auto mr-auto p-4 rounded-lg">
                    <div className="text-2xl">Store</div>

                    <div className="flex flex-col gap-2">
                        <div className="">
                            You've logged in daily for the past
                        </div>

                        <div className="p-2 px-3 bg-blue-600 w-fit rounded-full">
                            59 days
                        </div>
                    </div>
                </div>
            </div>


            <div className="flex flex-row gap-[1rem] w-[55vw] ml-auto mr-auto mb-[4rem]">

                <div className="p-3 rounded-xl flex items-center justify-center text-center bg-cyan-950 border-[3px] border-cyan-800 text-slate-100 w-[60%] h-[150px]">
                    <div className="text-cyan-500 text-3xl font-semibold flex gap-3 items-center">
                        <span className="material-symbols-outlined text-[2.5rem] font-semibold">
                            workspace_premium
                        </span>
                        Diamond League
                    </div>
                </div>

                <div className="p-3 rounded-xl flex items-center justify-center text-center bg-cyan-950 border-[3px] border-cyan-800 text-slate-100 w-[40%] h-[150px]">
                    <div className="text-cyan-500 text-3xl font-semibold flex gap-3 items-center">
                        500 xp
                    </div>
                </div>*/}

            </div> : <span className="mx-auto text-[2rem] flex justify-center my-[4rem] font-semibold text-slate-400">Loading...</span>}

            <Footer />
        </div>
    )
}

export default Streak
