import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import BookListCard from "../components/BookShelfElements/BookListCard";

export default function Shelves() {
    function capitalizeFirstLetters(string) {
        //return string.charAt(0).toUpperCase() + string.slice(1);
        // for first letter of all words
        return string.replace(/\b\w/g, l => l.toUpperCase());
    }

    // path is https://nerded.io/bookshelf/Travel
    // not using substring, but split
    // decodeURI to decode the URI
    const shelfCategory = capitalizeFirstLetters(decodeURI(window.location.pathname.split("/")[2]));

    function back() {
        window.history.back();
    }

    const LowerCaseShelfCategory = shelfCategory.toLowerCase();

    const [bookShelfData, setBookShelfData] = useState({});

    useEffect(() => {
        async function getBookShelfData() {
            await fetch(`https://nerded.io/api/bookshelves/bookshelf_list`)
            .then(response => response.json())
            .then(data => {
                console.log("REQUEST")
                console.log(data);
                setBookShelfData(data);
            })
        }

        getBookShelfData();
    }, [shelfCategory]);

    let bookShelfDataArray = [];

    for (let book in bookShelfData[LowerCaseShelfCategory]) {
        bookShelfDataArray.push(book);
    }

    console.log(bookShelfDataArray);
    console.log(typeof(bookShelfDataArray));

    return (
        <>
            <Navbar />

            <div onClick={back} className="fixed flex items-center justify-center gap-2 top-[6.5rem] left-10 rounded-xl cursor-pointer bg-slate-800 px-6 py-3 text-slate-300 group">
                <span>Back</span>
                <span className="material-symbols-outlined transform transition-transform duration-300 group-hover:translate-x-2 group-hover:-rotate-45">arrow_forward</span>
            </div>

            <div className="grid-new pt-[5rem] mt-[-1.5rem] pb-[0.5rem] mb-[-1.5rem]">

                <div className="w-fit pb-[4rem] ml-auto mr-auto text-[2.1rem] font-semibold">
                    {shelfCategory} Books
                </div>

                <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 ml-auto mr-auto gap-[12px] w-[70vw] mb-[5rem] justify-center">

                    {
                        (typeof(bookShelfDataArray) !== "undefined") ? (
                            bookShelfDataArray.map((title) => {

                                const linkTo = bookShelfData[LowerCaseShelfCategory][title].isbn !== "" ? `/book?isbn=${bookShelfData[LowerCaseShelfCategory][title].isbn}` : `/bookshelf/${LowerCaseShelfCategory}`;

                                return (
                                <Link to={linkTo} key={title} className="bg-slate-800 transition border-2 border-transparent hover:border-slate-600 p-3 flex flex-col gap-2 rounded-lg">
                                    <div className={`flex flex-row ${bookShelfData[LowerCaseShelfCategory][title].cover !== "" ? "gap-4" : "gap-2"}`}>
                                        <img src={bookShelfData[LowerCaseShelfCategory][title].cover.replace("http://", "https://")} className="rounded-lg" />
                                        <div className="w-full">
                                            <div className="flex flex-row justify-between items-center">
                                                <div className="font-bold text-[1.2rem]">{title}</div>
                                                <div className="bg-slate-900 py-2 px-3 rounded-lg w-fit font-normal flex items-center gap-1">

                                                    <span className="mb-[-3px]">
                                                        <ion-icon name="star"></ion-icon>
                                                    </span>
                                                    <span>{bookShelfData[LowerCaseShelfCategory][title].rating}</span>

                                                </div>
                                            </div>

                                            <div className="text-[1.1rem]">{bookShelfData[LowerCaseShelfCategory][title].author}</div>

                                            <div className="text-slate-300 pt-2">{bookShelfData[LowerCaseShelfCategory][title].description}</div>
                                        </div>
                                    </div>
                                </Link>)
                            })
                        ) : (
                            <div className="text-center text-slate-400 text-[1.2rem] font-thin">
                                No books found in this category.
                            </div>
                        )
                    }

                </div>
            </div>

            <Footer />
        </>
    )
}
