// Import necessary modules
import React from "react";
import Avatar from "boring-avatars";

// Define the wrapper component
export default function AvWrapper({
    name,
    size,
    square,
    variant,
    colors,
    className,
}) {

    const variantMapping = {
        "avatar": "beam",
        "pixel": "pixel",
        "sunset": "sunset",
        "rings": "ring",
        "abstract": "bauhaus",
    };

    return (
        <div
            className={className}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                height: `${size}px`, // or height size as per your design
                width: `${size}px`, // replace `aspectRatio` with your desired width/height ratio
            }}
        >
            <Avatar
                size={size}
                name={name}
                square={square}
                variant={variantMapping[variant]}
                colors={colors}
            />
        </div>
    );
}
