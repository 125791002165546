import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

function InputCenter(props) {
    const navigate = useNavigate();
    const inputRef = useRef(null);

    function enterSearch() {
        let prevLocation = window.location.pathname;
        
        if (inputRef.current.value !== "") {
            if (prevLocation === "/search") {
                props.searchWithQuery();
            }

            navigate("/search?q=" + inputRef.current.value);
            
        } else {
            toast.error("Please enter a search term")
        }
    }

    function focusSearch() {
        // focus on inputRef
        inputRef.current.focus();
    }

    // on press cmd + k
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.metaKey && event.key === "k") {
                focusSearch();
            }
        }

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        }
    }, []);

    //value={window.location.search.substring(3) ? decodeURIComponent(window.location.search.substring(3)) : ""}

    /*window.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
            //console.log("Enter Pressed");
            enterSearch();
        }
    })*/

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            //console.log('Enter key pressed! Value: ', event.target.value);
            if (inputRef.current.value !== "") {
                enterSearch();
            } else {
                toast.error("Please enter a search term")
            }
        }
    }, []);

    //console.log(window.location.pathname);

    const ifOnSearchPage = (window.location.pathname === "/search") ? true : false;
    const searchTerm = ifOnSearchPage ? window.location.search.substring(3) ? decodeURIComponent(window.location.search.substring(3)) : "" : "";

    ////console.log("InputCenter Loaded")

    // on press enter or

    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

    // on window resize
    useEffect(() => {
        const handleResize = () => {
            setWindowInnerWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return (
        <>
            {
                // if window size is sm
                (windowInnerWidth >= 700) ? 
                    <div className="flex-row items-center m-0 absolute right-0 translate-x-[calc(-50vw+12.5rem)]">
                        <input onKeyDown={handleKeyPress} defaultValue={searchTerm} ref={inputRef} type={props.type} placeholder={props.placeholder} className="bg-slate-700 text-slate-50 px-3 box-border rounded-lg p-2 outline-none border-2 border-transparent cursor-text w-[25rem] focus:border-sky-500" />
                        <span onClick={enterSearch} className="material-symbols-outlined z-20 translate-x-[22.7rem] mt-[-37.5px] absolute select-none text-slate-500 hover:text-sky-500 hover:bg-sky-950 cursor-pointer p-[0.13rem] rounded bg-slate-800 flex items-center justify-center">
                            keyboard_return
                        </span>
                    </div>
                : null
            }
            {
                (windowInnerWidth < 700) ? 
                    <div className="flex-row flex items-center">
                        <input onKeyDown={handleKeyPress} defaultValue={searchTerm} ref={inputRef} type={props.type} placeholder={props.placeholder} className="bg-slate-700 text-slate-50 px-3 box-border rounded-lg p-2 outline-none border-2 border-transparent cursor-text w-[9rem] focus:border-sky-500" />
                        {/*<span onClick={enterSearch} className="material-symbols-outlined z-20 select-none text-slate-500 ml-3 hover:text-sky-500 hover:bg-sky-950 cursor-pointer p-[0.13rem] rounded bg-slate-700 flex items-center justify-center">
                            keyboard_return
                        </span>*/}
                    </div>
                : null
            }
        </>
    )
}

export default InputCenter;