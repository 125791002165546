import { useState, useEffect, useRef } from "react";

import { useAuth } from "../../context/AuthContext";

import { Link } from "react-router-dom";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import ViewReview from "../../components/ReviewPage/ViewReview";

export default function ViewReviewPage() {
    return (
        <div className="grid-new">
            <Navbar />

            <div className="w-fit bg-slate-900 ml-auto mr-auto">
                <ViewReview />
            </div>

            <Footer />
        </div>
    )
}