import * as React from "react"

import { useRef } from "react"
import { useAuth } from "../../context/AuthContext"

import { toast } from "sonner"

import { Button } from "../ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
/*import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"*/

export default function RequestBookForm() {
    const { authenticated, login, logout, signup } = useAuth();

    const nameRef = useRef();
    const authorRef = useRef();

    async function handleRequestBookFormSubmit(e) {
        e.preventDefault();

        const name = nameRef.current.value;
        const author = authorRef.current.value;

        if (!name || !author) {
            toast(
                "Please fill out all fields!",
                {
                    duration: 3000,
                    type: "error",
                },
            )
            return;
        }

        await fetch("https://nerded.io/api/request-book", {
            body: JSON.stringify({
                name,
                author,
                session_token: authenticated.sessionToken,
                username: authenticated.name,
                email: authenticated.email
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        })
        .then((res) => res.json())
        .then((json) => {
            if (json.requestStatus === "error") {
                if (json?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast(
                    json.error,
                    {
                        duration: 3000,
                        type: "error",
                    }
                )
                return;
            } else if (json.requestStatus === "success") {
                toast(
                    "Book request submitted!",
                    {
                        duration: 3000,
                        type: "success",
                    }
                )

                nameRef.current.value = "";
                authorRef.current.value = "";
            }
        })

        /*toast(
            "Book request submitted!",
            {
                duration: 3000,
                type: "success",
            }
        )*/

        /*} catch (err) {
            console.error(err);
            toast(
                "An error occurred while submitting your book request!",
                {
                    duration: 3000,
                    type: "error",
                }
            )
        }*/
    }

    return (
    <Card className="w-[400px] border-slate-700 shadow-xl shadow-slate-950 bg-slate-900">
      <CardHeader>
        <CardTitle>
            Request a new book addition
        </CardTitle>
        <CardDescription className="text-[1rem] pt-2">
            Fill out the form below to request a new book addition to the library.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form>
            <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="name">
                        Book Name
                    </Label>
                <Input id="name" ref={nameRef} className="h-[3rem] bg-slate-800 border-2 border-slate-700" placeholder="Book Name" />
                </div>
                <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="name">
                        Book Author
                    </Label>

                    <Input id="name" ref={authorRef} className="h-[3rem] bg-slate-800 border-2 border-slate-700" placeholder="Book Author" />
                </div>
            </div>
        </form>
      </CardContent>
      <CardFooter className="flex justify-end w-full">
        <Button className="flex flex-row items-center gap-2" onClick={handleRequestBookFormSubmit}>

            <span className="material-symbols-outlined">
                send
            </span>

            Send Request

        </Button>
      </CardFooter>
    </Card>
  )
}
