import { useState, useEffect } from "react";

import { useAuth } from '../context/AuthContext';
import BookReviewModal from "./BookReviewModal";

import { toast } from "sonner"
import ShareBookModal from "./BookActionButtonsSub/ShareBookModal";

import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "./ui/hover-card"


function BookActionButtons(props) {

    const { authenticated, login, signup, logout } = useAuth();

    const [readList, setReadList] = useState([]);
    const [bookmarkedList, setBookmarkedList] = useState([]);
    const [booksReviewed, setBooksReviewed] = useState([]);

    const [bookHasBeenReviewed, setBookHasBeenReviewed] = useState(false);

    let currentlySendingRead = false;
    let currentlySendingBookmarked = false;

    useEffect(() => {
        if (!authenticated.authenticated) {
            setReadList([]);
            setBookmarkedList([]);
            setBooksReviewed([]);
        }
    }, [authenticated.authenticated])

    function updateHomeFeedStats() {
        // check if the user is on the home page
        if (Object.keys(props).includes("updateHomeFeedStats")) {
            // if so, update the home feed stats
            props.updateHomeFeedStats();
        }
    }

    // refrsh the reviews list when page changes urls
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    useEffect(() => {
        setBookmarkedList(props.bookmarkedList);
        setReadList(props.readList);
        setBooksReviewed(props.booksReviewed);

        let foundBook = false;

        for (let review of props.booksReviewed) {
            // check if the user id matches the current user id
            if (review.isbn === props.isbn) {
                // check if the isbn matches the current isbn
                setBookHasBeenReviewed(true);
                foundBook = true;
                break;
            }
        }

        if (!foundBook) {
            setBookHasBeenReviewed(false);
        }

    }, [props.bookmarkedList, props.readList, props.booksReviewed, currentUrl])

    const bookIsbn = props.isbn;
    const showSendButton = props.showSendButton;

    async function markAsRead() {

        if (!authenticated.authenticated) {
            login();
            return;
        }

        //setReadList([...readList, bookIsbn]);
        if (currentlySendingRead) {
            return;
        }

        currentlySendingRead = true;

        await fetch(`https://nerded.io/api/user_actions/mark_read_book`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user_id: authenticated.userId,
                session_token: authenticated.sessionToken,
                isbn: bookIsbn,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            } else {

                //console.log(data)
                // add the current isbn to the read list
                setReadList([...readList, bookIsbn]);
                updateHomeFeedStats();

                currentlySendingRead = false;
            }

            //const leveling_data = data["leveling_data"];

            //if (leveling_data.user_leveled_up) {
            //    toast.success(`Congratulations! You have leveled up to level ${leveling_data.user_new_level}!`, "success");
            //}
        })
    }

    async function markAsBookmarked() {

        if (!authenticated.authenticated) {
            login();
            return;
        }

        //setBookmarkedList([...bookmarkedList, bookIsbn]);

        if (currentlySendingBookmarked) {
            return;
        }

        currentlySendingBookmarked = true;

        await fetch(`https://nerded.io/api/user_actions/mark_bookmarked_book`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    isbn: bookIsbn,
                })
            })
            .then(response => response.json())
            .then(data => {
                //console.log(data)
                // add the current isbn to the bookmarked list

                setBookmarkedList([...bookmarkedList, bookIsbn]);
                updateHomeFeedStats();

                currentlySendingBookmarked = false;
            })
    }

    async function unmarkAsRead() {

        if (currentlySendingRead) {
            return;
        }

        currentlySendingRead = true;

        await fetch(`https://nerded.io/api/user_actions/unmark_read_book`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    isbn: bookIsbn,
                })
            })
            .then(response => response.json())
            .then(data => {
                //console.log(data)
                // remove the current isbn from the read list

                setReadList(readList.filter((isbn) => isbn !== bookIsbn));
                updateHomeFeedStats();

                currentlySendingRead = false;
            })
    }

    async function unmarkAsBookmarked() {
        //setBookmarkedList(bookmarkedList.filter((isbn) => isbn !== bookIsbn));
        if (currentlySendingBookmarked) {
            return;
        }

        currentlySendingBookmarked = true;

        await fetch(`https://nerded.io/api/user_actions/unmark_bookmarked_book`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                    isbn: bookIsbn,
                })
            })
            .then(response => response.json())
            .then(data => {
                //console.log(data)
                // remove the current isbn from the bookmarked list
                //setBookmarkedList(bookmarkedList.filter((isbn) => isbn !== bookIsbn));

                setBookmarkedList(bookmarkedList.filter((isbn) => isbn !== bookIsbn));
                updateHomeFeedStats();

                currentlySendingBookmarked = false;
            })
    }

    const [showReviewModal, setShowReviewModal] = useState(false);

    function writeReview() {
        if (!authenticated.authenticated) {
            login();
            return;
        }

        if (bookHasBeenReviewed) {
            window.location.href = `/review/${bookIsbn}`;
            return;
        }

        // check if book has been read, if not, mark as read
        // error if book has not been read
        if (!readList.includes(bookIsbn)) {
            //markAsRead();
            // toast alert
            toast.error("You have not read this book yet!", "error");
            //markAsRead();
            //showToast("You have not marked this book as read yet! Marking as read...", "success");
        } else {
            setShowReviewModal(true);
        }

        // window.location.href = `/review/${bookIsbn}`;

    }

    function refreshReviewsListHandler() {
        if (Object.keys(props).includes("refreshReviewsList")) {
            props.refreshReviewsList();

            try {
                if (Object.keys(props).includes("updateHomeFeedStats")) {
                    props.updateHomeFeedStats();
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    function updateHomeFeedStatsHandler() {
        if (Object.keys(props).includes("updateHomeFeedStats")) {
            props.updateHomeFeedStats();
        }
    }

    const [showBookShareModal, setShowBookShareModal] = useState(false);

    function showBookShareModalHandler() {
        if (!authenticated.authenticated) {
            login();
            return;
        }

        setShowBookShareModal(true);
    }

    return (
        <div className="md:flex lg:flex xl:flex 2xl:flex grid grid-cols-2 flex-row flex-wrap gap-2 items-center justify-end min-w-fit">

            <HoverCard>
                <HoverCardTrigger>
                    <div onClick={readList.includes(bookIsbn) ? unmarkAsRead : markAsRead} className={`cursor-pointer select-none w-fit flex flex-row gap-1 p-3 py-[0.3rem] px-[0.7rem] rounded-lg ${readList.includes(bookIsbn) ? "bg-emerald-900 text-emerald-400 hover:bg-emerald-800" : "bg-slate-700 hover:bg-slate-600"} items-center`}>
                        <div className="material-symbols-outlined text-2xl">
                            check {/*favorite*/}
                        </div>
                    </div>
                </HoverCardTrigger>
                <HoverCardContent side="top" className="outline-[1px] outline-slate-700 outline-offset-0 backdrop-blur-sm bg-slate-900/50 w-fit m-[10px] py-[10px]">
                    Read
                </HoverCardContent>
            </HoverCard>

            <HoverCard>
                <HoverCardTrigger>
                    <div onClick={bookmarkedList.includes(bookIsbn) ? unmarkAsBookmarked : markAsBookmarked} className={`cursor-pointer w-fit select-none flex flex-row gap-1 p-3 py-[0.3rem] px-[0.7rem] rounded-lg ${bookmarkedList.includes(bookIsbn) ? "bg-emerald-900 text-emerald-400 hover:bg-emerald-800" : "bg-slate-700 hover:bg-slate-600"} items-center`}>
                        <div className="material-symbols-outlined text-2xl">
                            bookmarks {/*bookmark*/}
                        </div>
                    </div>
                </HoverCardTrigger>
                <HoverCardContent side="top" className="outline-[1px] outline-slate-700 outline-offset-0 backdrop-blur-sm bg-slate-900/50 w-fit m-[10px] py-[10px]">
                    Want to Read
                </HoverCardContent>
            </HoverCard>

            {showSendButton ?
            <HoverCard>
                <HoverCardTrigger>
                    <div onClick={showBookShareModalHandler} className="cursor-pointer flex flex-row gap-1 p-3 w-fit py-[0.3rem] px-[0.7rem] rounded-lg bg-slate-700 hover:bg-slate-600 items-center">
                        <span className="material-symbols-outlined text-2xl">
                            send
                        </span>
                    </div>
                </HoverCardTrigger>
                <HoverCardContent side="top" className="outline-[1px] outline-slate-700 outline-offset-0 backdrop-blur-sm bg-slate-900/50 w-fit m-[10px] py-[10px]">
                    Share via Email
                </HoverCardContent>
            </HoverCard>
            : null}

            <HoverCard>
                <HoverCardTrigger>
                    <div onClick={writeReview} className={`cursor-pointer select-none flex w-fit flex-row gap-1 p-3 py-[0.3rem] px-[0.7rem] rounded-lg ${bookHasBeenReviewed && authenticated.authenticated ? "bg-emerald-900 text-emerald-400 hover:bg-emerald-800" : "bg-slate-700 hover:bg-slate-600"} items-center`}>
                        <div className="material-symbols-outlined text-2xl">
                            reviews
                        </div>
                    </div>
                </HoverCardTrigger>
                <HoverCardContent side="top" className="outline-[1px] outline-slate-700 outline-offset-0 backdrop-blur-sm bg-slate-900/50 w-fit m-[10px] py-[10px]">
                    Write a Review
                </HoverCardContent>
            </HoverCard>

            <BookReviewModal bookData={props.bookData} showReviewModal={showReviewModal} setShowReviewModal={setShowReviewModal} refreshReviewsList={refreshReviewsListHandler} updateHomeFeedStats={updateHomeFeedStatsHandler} />
            <ShareBookModal isbn={bookIsbn} bookData={props.bookData} showBookShareModal={showBookShareModal} setShowBookShareModal={setShowBookShareModal} />
        </div>
    )
}

export default BookActionButtons;
