import * as React from "react"

import { Button } from "../ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"

import { useRef, useState } from "react"
import { useAuth } from "context/AuthContext"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

export default function CreateBookClubForm() {
  const Navigate = useNavigate()
  const { authenticated, login, logout } = useAuth();

  if (!authenticated.authenticated) {
    toast(
      "You must be logged in to create a book club!",
      {
        duration: 3000,
        type: "error",
      },
    )

    //Navigate("/login")

    login()
  }

  const [nameInput, setNameInput] = useState("")
  const [privacyInput, setPrivacyInput] = useState("")

  function inputHandler(e) {
    setNameInput(e.target.value)
  }

  function privacyInputHandler(value) {
    setPrivacyInput(value)
  }

  async function submitForm() {
    const name = nameInput
    const privacy = privacyInput

    console.log(name)

    if (!name || !privacy) {
      toast.error("Oops! Please fill out your book club name and privacy setting.",)
      return;
    }

    await fetch("https://nerded.io/api/restricted/bookclub/create", {
      body: JSON.stringify({
        name,
        privacy_setting: privacy,
        session_token: authenticated.sessionToken,
        user_id: authenticated.userId,
        //username: authenticated.name,
        //email: authenticated.email
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if (res.requestStatus === "success") {
          toast.success("Book club created!")
          Navigate(`/bookclub/view?id=${res.book_club_id}`)
          //Navigate("/bookclub/view")
        } else {
            if (res?.relog) {
                toast.error("Please login again!");
                logout();
            }

          toast.error("Error creating book club!")
        }
      })
  }

  return (
    <Card className="w-[26rem] bg-slate-900 border-slate-600 shadow-xl shadow-slate-950">
      <CardHeader>
        <CardTitle>Create a Book Club</CardTitle>
        <CardDescription>
            Create a book club to share your reading with friends.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-3">
              <Label htmlFor="name">Name</Label>
              <Input onChange={inputHandler} id="name" className="bg-slate-900 border border-slate-700" placeholder="Name of your club" />
            </div>
            <div className="flex flex-col space-y-3">
              <Label htmlFor="privacy-setting">Privacy Setting</Label>
              <Select onValueChange={privacyInputHandler}>
                <SelectTrigger id="privacy-setting">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent position="popper" className="bg-slate-950">
                  <SelectItem value="public">Public</SelectItem>
                  <SelectItem value="invite">Invite Only</SelectItem>
                  <SelectItem value="private">Private</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </form>
      </CardContent>
      <CardFooter className="flex justify-end w-full">
        <Button className="rounded-lg hover:bg-emerald-900/50 bg-emerald-950/50 text-emerald-400 border text-[1rem] border-emerald-700 px-3 py-3" onClick={submitForm}>Create</Button>
      </CardFooter>
    </Card>
  )
}
