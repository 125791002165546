import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { Link } from "react-router-dom";

import WordSearchIcon from "../../assets/wordsearch.png";

export default function GamesList() {
	return (
		<div>
			<Navbar />

			<div>
				<h1 className="text-4xl text-slate-300 mt-[2rem] font-bold mx-auto w-fit bg-gradient-to-b from-emerald-100 to-emerald-300 bg-clip-text text-transparent">
					Games
				</h1>
			</div>

			<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-[95vw] md:max-w-[95vw] lg:max-w-[90vw] xl:max-w-[75vw] 2xl:max-w-[75vw] w-fit rounded-lg mx-auto mt-[2rem] mb-[4rem] gap-[2rem]">
				<div className="flex flex-row gap-4 bg-slate-900 justify-center p-3 w-[17rem] rounded-lg border border-slate-700 shadow-xl">
					<div className="flex flex-col gap-2 items-center w-full">
						<h2 className="text-xl text-slate-100 mt-1">
							Word Search
						</h2>
						<img
							src={WordSearchIcon}
							alt="Word Search"
							className="w-[12rem] h-[12rem]"
						/>
						<Link
							to="/games/wordsearch"
							className="flex flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-slate-700 transition hover:border-slate-700 hover:bg-slate-700/20 cursor-pointer"
						>
							Play
						</Link>
					</div>
				</div>

				<div className="flex flex-row gap-4 bg-slate-900 justify-center p-3 w-[17rem] rounded-lg border border-slate-700 shadow-xl">
					<div className="flex flex-col gap-2 items-center w-full">
						<h2 className="text-xl text-slate-100 mt-1">
							Cover Jigsaw
						</h2>

						<div className="grid grid-cols-3 gap-0">
							<div></div>
							<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] mb-[-1.9rem] flex justify-center">
								extension
							</span>
							<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] mb-[-1.9rem] ml-[-3.7rem] flex justify-center">
								extension
							</span>
							<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] mr-[-3.8rem] flex justify-center">
								extension
							</span>
							<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] flex justify-center">
								extension
							</span>
							<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] ml-[-3.8rem] flex justify-center">
								extension
							</span>
							<div></div>
							<span className="material-symbols-outlined text-[5rem] text-slate-600 font-[300] mt-[-1.9rem] flex justify-center">
								extension
							</span>
							<div></div>
							<div></div>
						</div>

						<Link
							to="/games/coverpuzzle"
							className="flex flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-slate-700 transition hover:border-slate-700 hover:bg-slate-700/20 cursor-pointer"
						>
							Play
						</Link>
					</div>
				</div>

				<div className="flex flex-row col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-1 2xl:col-span-1 mx-auto gap-4 bg-slate-900 justify-center p-3 w-[17rem] rounded-lg border border-slate-700 shadow-xl">
					<div className="flex flex-col gap-2 items-center w-full">
						<h2 className="text-xl text-slate-100 mt-1">
							Spine Stacker
						</h2>

						<div className="flex flex-col gap-[4px] w-[85%] justify-end h-full mb-2 items-center">
							<div className="flex flex-row gap-1 bg-blue-600/70 h-[2rem] p-2 w-[90%] mr-[5%] rounded py-3"></div>
							<div className="flex flex-row gap-1 bg-yellow-600/70 h-[2rem] p-2 w-[90%] ml-[5%] rounded py-3"></div>
							<div className="flex flex-row gap-1 bg-green-600/70 h-[2rem] p-2 w-[90%] mr-[5%] rounded py-3"></div>
							<div className="flex flex-row gap-1 bg-red-600/70 h-[2rem] p-2 w-[90%] ml-[10%] rounded py-3"></div>
						</div>

						<Link
							to="/games/spinestacker"
							className="flex flex-row gap-2 mt-auto rounded-full py-2 w-full justify-center items-center px-4 border border-slate-700 transition hover:border-slate-700 hover:bg-slate-700/20 cursor-pointer"
						>
							Play
						</Link>
					</div>
				</div>
			</div>

            <div className="flex flex-col mb-[4rem] gap-4 justify-center w-[40vw] mx-auto">
                <span className="font-semibold flex flex-row text-slate-200 items-center gap-2">
                    <span className="material-symbols-outlined text-[1.3rem]">info</span>
                    <span className="text-lg">About the games</span>
                </span>

                <span className="text-slate-400 text-lg">
                    Nerded Games is a collection of games and puzzles that change every day.

                    The games are designed to be simple and fun, and are perfect for a quick break or to pass the time.
                </span>
            </div>

			<Footer />
		</div>
	);
}
