import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "../ui/carousel"

import SimilarBookCard from "./SimilarBookCard";

export default function SimilarBookSection(props) {

    if (props.similarBooks === undefined) {
        return (
            <div className="flex flex-row items-center w-[70vw]">
                <p className="text-[1.2rem] text-center w-full">
                    An error occurred while fetching similar books...
                </p>
            </div>
        );
    }

    return (
        <div className="flex flex-row items-center w-[70vw]">
            {props?.similarBooks?.length === 0 ? <p className="text-[1.5rem] text-center w-full">Loading...</p> :

                <>
                    <Carousel className="w-full">
                        <CarouselContent className="gap-[1rem]">
                            {props.similarBooks.map((book, index) => {

                                let author = "Unknown";

                                try {
                                    author = book.volumeInfo.authors[0] ? book.volumeInfo.authors[0] : "Unknown";
                                } catch (error) {}

                                if (Object.keys(book.volumeInfo).includes("industryIdentifiers") === false) {
                                    return null;
                                }

                                // if no image links
                                if (Object.keys(book.volumeInfo).includes("imageLinks") === false) {
                                    return null;
                                }

                                return (
                                    <CarouselItem key={index} className="max-w-[20rem]">
                                        <SimilarBookCard
                                            key={index}
                                            image={book.volumeInfo.imageLinks.thumbnail}
                                            title={book.volumeInfo.title}
                                            author={author}
                                            isbn={book.volumeInfo.industryIdentifiers[0].identifier}
                                        />
                                    </CarouselItem>
                                )
                            })}

                        </CarouselContent>

                        <CarouselPrevious className="rounded" />
                        <CarouselNext className="rounded" />
                    </Carousel>
                </>
            }
        </div>
    )
}
