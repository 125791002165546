import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
  } from "../ui/carousel"

import FeedBookItem from "./FeedBookItem"

export default function RecommendedCarousel() {
    return (
        <Carousel>
            <CarouselContent className="">

                <CarouselItem className="sm:basis-1/1 md:basis-1/1 lg:basis-1/2 xl:basis-1/2">
                    <FeedBookItem
                        img="https://books.google.com/books/content?id=FY_HWAcm10MC&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api"
                        title="Ready Player One"
                        author="Ernest Cline"
                        description="Ready Player One is a science fiction ruled by the virtual reality world of OASIS. The story follows Wade Watts on his quest to find an Easter egg in the game that will give him a fortune."
                        link="/book?isbn=9780307887450"
                    />
                </CarouselItem>

                <CarouselItem className="sm:basis-1/1 md:basis-1/1 lg:basis-1/2 xl:basis-1/2">
                    <FeedBookItem
                        img="https://books.google.com/books/content?id=dI6cEAAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api"
                        title="Tweet Cute"
                        author="Emma Lord"
                        description="Tweet Cute is a young adult contemporary romance about Pepper and Jack, high schoolers who manage their family's business social media accounts. Starting as rivals, they soon fall for each other."
                        link="/book?isbn=9781035009114"
                    />
                </CarouselItem>

                <CarouselItem className="sm:basis-1/1 md:basis-1/1 lg:basis-1/2 xl:basis-1/2">
                    <FeedBookItem
                        img="https://books.google.com/books/content?id=oMXQBAAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api"
                        title="Looking For Alaska"
                        author="John Green"
                        description="Looking for Alaska is a young adult novel that follows Miles Halter as he attends Culver Creek Preparatory School. There, he meets Alaska Young, a mysterious and enigmatic student who changes his life forever."
                        link="/book?isbn=9780698405875"
                    />
                </CarouselItem>

            </CarouselContent>

            <CarouselPrevious className="rounded" />
            <CarouselNext className="rounded" />

        </Carousel>
    )
}