import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

export default function StatusPage() {
    const { authenticated, login, logout } = useAuth();

    return (
        <div className="grid-new">
            <Navbar showAccountPrompt={true} />

            <div className="md:max-w-[50vw] sm:max-w-[90vw] md:max-w[90vw] mt-[2rem] mb-[3.4rem] ml-auto mr-auto">
                <div className="bg-slate-800 mx-[1rem]">
                    <div className="flex justify-center items-center h-[15rem] p-3 border-[2px] border-emerald-600 bg-emerald-950/40 py-3 rounded-lg">
                        <span className="text-[1.2rem] text-center flex items-center justify-center text-emerald-400">
                            Our systems are up! {authenticated.authenticated ? "You are logged in." : "You are not logged in."}
                        </span>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}