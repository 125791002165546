function StarRating(props) {
    const rating = props.rating || null;
    const type = props.type || "normal";

    let color;

    if (type === "viewer") {
        color = "text-yellow-400";
    } else if (type === "normal") {
        color = "text-emerald-500";
    } else if (type === "slate") {
        color = "text-slate-500";
    } else if (type === "yellow-non-viewer") {
        color = "text-amber-500";
    }

    let wholeStarCount = Math.floor(rating);
    let halfStarCount = Math.ceil(rating - wholeStarCount);
    let emptyStarCount = 5 - wholeStarCount - halfStarCount;

    return (
        <div className="flex flex-row">
            {/*{[...Array(5)].map((_, index) => {
                const starValue = index + 1;

                return (
                    <div
                        key={index}
                        // allow user rating to be 0.5
                        className={`${type === "viewer" ? "text-[1.4rem]" : "text-3xl"} justify-center focus:outline-none items-center flex ${starValue <= rating ? color : (starValue - 0.5) <= rating ? color : 'text-gray-400'}`}                    >
                        {starValue <= rating ? <ion-icon name="star"></ion-icon> : (starValue - 0.5) <= rating ? <ion-icon name="star-half-outline"></ion-icon> : <ion-icon name="star-outline"></ion-icon>}
                    </div>
                );
            })}*/}

            {
                [...Array(wholeStarCount)].map((_, index) => {
                    return (
                        <div
                            key={index}
                            className={`${type === "viewer" ? "text-[1.4rem]" : "text-3xl"} justify-center focus:outline-none items-center flex ${color}`}
                        >
                            <ion-icon name="star"></ion-icon>
                        </div>
                    );
                })
            }

            {
                [...Array(halfStarCount)].map((_, index) => {
                    return (
                        <div
                            key={index}
                            className={`${type === "viewer" ? "text-[1.4rem]" : "text-3xl"} justify-center focus:outline-none items-center flex ${color}`}
                        >
                            <ion-icon name="star-half-outline"></ion-icon>
                        </div>
                    );
                })
            }

            {
                [...Array(emptyStarCount)].map((_, index) => {
                    return (
                        <div
                            key={index}
                            className={`${type === "viewer" ? "text-[1.4rem]" : "text-3xl"} justify-center focus:outline-none items-center flex ${color}`}
                        >
                            <ion-icon name="star-outline"></ion-icon>
                        </div>
                    );
                })
            }
        </div>
    );
}


export default StarRating;
