import { useState, useRef } from "react";

import { useAuth } from "../../context/AuthContext";

import { toast } from "sonner"

function ShareBookModal(props) {

    const { authenticated, login, logout, signup } = useAuth();

    //const [emailInput, setEmailInput] = useState("");

    const emailRef = useRef(null);

    function clearEmail(closeModal=false) {
        emailRef.current.value = "";
        //setEmailInput("");

        if (closeModal) {
            props.setShowBookShareModal(false);
        }
    }

    let currentlySendingRequest = false;

    async function submitShare() {

        if (emailRef.current.value === "") {
            toast.error("Please add an email!");
            return;
        }

        // if has @ and . (includes)
        if (!emailRef.current.value.includes("@") || !emailRef.current.value.includes(".")) {
            toast.error("Please add a valid email!");
            return;
        }

        if (currentlySendingRequest) {
            toast.error("Please wait for the current request to finish!");
            return
        }

        currentlySendingRequest = true;

        let tempValue = emailRef.current.value;

        toast.success("Successfully shared book!");
        clearEmail(true);

        await fetch(`https://nerded.io/api/user_actions/share_book_via_email`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user_id: authenticated.userId,
                session_token: authenticated.sessionToken,
                isbn: props.bookData.industryIdentifiers[0].identifier,
                recipient: tempValue,
            })
        })
        .then(response => response.json())
        .then(data => {
            //console.log(data);

            if (!data.requestStats === "success") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error("Failed to share book!");
            }

            currentlySendingRequest = false;
        })
    }

    return (
        <>
            <div className={`fixed bg-slate-950 border-[1.5px] border-slate-800 gap-[0.8rem] p-[1.3rem] md:w-[40vw] lg:w-[40vw] xl:w-[40vw] 2xl:w-[40vw] w-[90vw] h-fit inset-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-start justify-start flex-col rounded-md z-20 ${props.showBookShareModal ? "flex" : "hidden"}`}>
                <div className="text-[1.5rem] flex flex-row justify-between w-full">
                    <span className="truncate">
                        Share {props.bookData.title}
                    </span>
                    <span className="font-semibold material-symbols-outlined hover:bg-slate-800 text-[1.5rem] mt-[-5px] p-[10px] cursor-pointer rounded-full items-center flex" onClick={() => props.setShowBookShareModal(false)}>close</span>
                </div>

                <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-3 w-full items-center border-t border-t-slate-800 pt-[20px]">
                    <input ref={emailRef} className="w-full h-[3rem] text-[1.2rem] bg-slate-900 rounded-lg border-slate-700 p-[10px] text-slate-300 focus:border-slate-600 border-[1.5px] outline-none" placeholder="Email to share book to"></input>

                    <div onClick={submitShare} className="select-none p-2 h-[3rem] items-center flex bg-slate-800 text-slate-100 rounded-md md:w-fit lg:w-fit xl:w-fit 2xl:w-fit justify-center md:justify-left lg:justify-left xl:justify-left 2xl:justify-left w-full px-5 cursor-pointer hover:bg-slate-700">
                        Submit
                    </div>
                </div>
            </div>

            <div onClick={() => props.setShowBookShareModal(false)} className={`fixed z-10 inset-0 cursor-pointer bg-black bg-opacity-70 ${props.showBookShareModal ? "flex" : "hidden"}`}></div>
        </>
    )
}

export default ShareBookModal;
