import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

export default function PrivacyPolicy() {
    return (
        <div className="min-h-screen flex flex-col grid-new">
            <Navbar />
            <div className="flex flex-col items-center justify-center flex-grow">
                <p className="text-slate-200 text-left max-w-[80vw] mx-auto p-6 bg-slate-900 shadow-lg shadow-slate-950 border border-slate-800 my-[2rem] rounded-[1rem] flex flex-col gap-[1rem]">
                    <strong className="text-2xl mb-4 block">NERDED.IO PRIVACY POLICY</strong>
                    <span className="block mb-2">Last Updated: December 27, 2023</span>
                    <span className="block mb-4">
                        Welcome to Nerded.io! This Privacy Policy outlines how we collect, use, and protect your personal
                        information. By using Nerded.io, you consent to the practices described in this Privacy Policy,
                        which can be found at <a href="https://nerded.io/legal/privacy" className="text-blue-500" target="_blank" rel="noopener noreferrer">https://nerded.io/legal/privacy</a>.
                    </span>

                    <span className="block mb-2">
                        <strong>1. Information We Collect:</strong> - We collect various types of information, including
                        account information, profile information, and usage data, to provide, maintain, and improve
                        Nerded.io's features and functionality.
                    </span>

                    <span className="block mb-2">
                        <strong>2. How We Use Your Information:</strong> - We use your information for operational
                        purposes, communication, personalization, and to enhance your overall experience on Nerded.io.
                    </span>

                    <span className="block mb-2">
                        <strong>3. Data Security:</strong> - We implement industry-standard security measures to protect
                        your data. However, no method of transmission over the internet is entirely secure.
                    </span>

                    <span className="block mb-2">
                        <strong>4. Data Sharing:</strong> - We do not sell your personal information to third parties.
                        Your user-generated content may be visible to other users as part of the Platform's core functionality.
                    </span>

                    <span className="block mb-2">
                        <strong>5. Cookies and Tracking:</strong> - Nerded.io uses cookies and similar tracking
                        technologies to enhance your experience and collect information about how you use the Platform.
                    </span>

                    <span className="block mb-2">
                        <strong>6. Third-Party Links:</strong> - Nerded.io may contain links to third-party websites. We
                        are not responsible for the privacy practices or content of these sites.
                    </span>

                    <span className="block mb-2">
                        <strong>7. Children's Privacy:</strong> - Nerded.io is not intended for users under the age of 13.
                        If you believe a child under 13 has provided us with personal information, please contact us.
                    </span>

                    <span className="block mb-2">
                        <strong>8. Changes to this Privacy Policy:</strong> - We may update this Privacy Policy to
                        reflect changes in our practices. Your continued use of Nerded.io after changes constitutes
                        acceptance of the updated policy.
                    </span>

                    <span className="block mb-4">
                        <strong>9. Contact Information:</strong> - For questions or concerns regarding this Privacy
                        Policy, please contact us at <a href="mailto:support@nerded.io" className="text-blue-500">support@nerded.io</a>.
                    </span>

                    <span className="block">
                        Thank you for entrusting us with your information. Enjoy your time on Nerded.io!
                    </span>
                </p>
            </div>
            <Footer />
        </div>
    );
}
