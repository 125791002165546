import { Link } from "react-router-dom";

import catImage from "../../assets/catSplash.PNG";

import Navbar from "../../components/Navbar"

import React, { useState, useEffect } from 'react';

import { useAuth } from '../../context/AuthContext';

function LoginPage() {
    const { authenticated, login, signup, logout } = useAuth();

    const [passwordShown, setPasswordShown] = useState("password");
    const [visibilityIcon, setVisibilityIcon] = useState("visibility");

    function togglePasswordVisibility() {
        if (passwordShown === "password") {
            setPasswordShown("text");
            setVisibilityIcon("visibility_off");
        } else {
            setPasswordShown("password");
            setVisibilityIcon("visibility");
        }
    }

    //const [ user, setUser ] = useState([]);
    //const [ profile, setProfile ] = useState([]);

    /*useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                    })
                    .catch((err) => {
                        console.log(err)
                        setProfile(null);
                    });
            }
        },
        [ user ]


    );*/

    //{/*profile={profile}*/}

    return (
        <div>

            <Navbar showAccountPrompt={false} />

            {/*{authenticated.authenticated ? (
                <div>
                    <img src={authenticated.picture} alt="user image" />
                    <h3>User Logged in!</h3>
                    <p>Name: {authenticated.name}</p>
                    <p>Email Address: {authenticated.email}</p>
                    <button className="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150" onClick={LogoutFunction}>Log out</button>
                </div>
            ) : (
                <div className="flex justify-center flex-col items-center">
                    <div className="flex w-[23rem] text-lg gap-4 bg-slate-800 p-4 rounded-xl flex-col absolute top-[55%] transform -translate-y-1/2">
                        <div>
                            Log into Nerded
                        </div>

                        <div className="flex flex-col gap-[10px]">
                            <input className="border-2 border-slate-700 focus:border-sky-500 rounded-md p-3 outline-none bg-slate-800 text-slate-200" type="text" placeholder="Email" />

                            <div className="flex flex-row w-full items-center">
                                <input className="border-2 w-full border-slate-700 focus:border-sky-500 rounded-md p-3 outline-none bg-slate-800 text-slate-200" type={passwordShown} placeholder="Password" />

                                <span className="material-symbols-outlined cursor-pointer select-none text-slate-500 absolute left-[19.5rem]" onClick={togglePasswordVisibility}>
                                    {visibilityIcon}
                                </span>
                            </div>

                            <button className="px-5 py-2 border gap-2 border-slate-700 rounded-lg text-slate-200 hover:border-slate-500 hover:text-slate-300 hover:shadow transition duration-150">
                                Login
                            </button>

                        </div>

                        <div className="border-t border-t-slate-700 w-full"></div>

                        <button onClick={LoginFunction} className="w-fit ml-auto mr-auto items-center px-4 py-2 border flex gap-2 border-slate-700 rounded-lg text-slate-200 hover:border-slate-500 hover:text-slate-300 hover:shadow transition duration-150">
                            <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                            <span>Login with Google</span>
                        </button>

                    </div>

                    <span className="absolute top-[82%] text-slate-400">
                        Don't have an account? <a href="/signup" className="text-sky-400 hover:underline font-semibold">Sign up</a>
                    </span>
                </div>
            )}*/}

            <div className="flex flex-row justify-between bg-slate-900 rounded-lg w-[60vw] mt-[1.5rem] ml-auto mr-auto">
                <div className="flex flex-col justify-center gap-[1rem] bg-slate-900 h-full w-full p-[2rem]">
                    <h1 className="text-[1.8rem] text-slate-300 font-semibold">
                        Login
                    </h1>

                    <div className="flex flex-col gap-[10px] p-3 mt-[25%] rounded-xl">
                        {/*<input className="border-2 border-slate-700 focus:border-blue-500 rounded-md p-3 outline-none bg-slate-800 text-slate-200" type="text" placeholder="Email" />

                        <div className="flex flex-row w-full items-center border-2 border-slate-700 rounded-md focus-within:border-blue-500">
                            <input className="w-full rounded-md p-3 outline-none bg-slate-800 text-slate-200" type={passwordShown} placeholder="Password" />

                            <span className="material-symbols-outlined cursor-pointer select-none mr-[0.8rem] text-slate-500" onClick={togglePasswordVisibility}>
                                {visibilityIcon}
                            </span>
                        </div>

                        <div className="cursor-pointer bg-slate-800 border-[2px] border-slate-700 hover:border-slate-600 justify-center flex text-white font-semibold py-3 px-4 rounded-md">
                            Login
                        </div>*/}

                        <button onClick={login} className="w-full mb-1 ml-auto mr-auto items-center justify-center px-4 py-3 border-[2px] flex gap-2 border-slate-700 rounded-md text-slate-200 hover:border-slate-600">
                            <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                            <span>Login with Google</span>
                        </button>

                        <div className="flex flex-row gap-[13px] items-center">
                            <div className="border-t-2 border-t-slate-700 w-full"></div>
                            <span className="text-slate-500">
                                or
                            </span>
                            <div className="border-t-2 border-t-slate-700 w-full"></div>
                        </div>

                        <Link to="/signup" className="text-slate-400 mt-1 hover:border-slate-600 hover:text-slate-300 text-center font-normal px-4 py-2 border-slate-700 border-2 rounded-full">
                            Signup Instead
                        </Link>

                    </div>
                </div>

                <div className="h-[75vh] hidden lg:block xl:block 2xl:block">
                    <img className="h-[75vh] min-w-[34vw] rounded-xl" src={catImage} />
                </div>
            </div>
        </div>
    )
}

export default LoginPage
