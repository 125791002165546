import { Link } from "react-router-dom";

export default function FeedBookItem(props) {
    return (
        <Link to={props.link} className="bg-slate-800 p-[10px] rounded-xl hover:border-emerald-600 h-[14rem] border-[2px] border-transparent flex items-center flex-row sm:flex-wrap xs:flex-nowrap md:flex-nowrap gap-[1rem]">
            <img src={props.img} alt="book cover" className="h-[200px] rounded-lg" />
            <div className="flex flex-col gap-2 h-full w-full items-start">
                <span className="flex flex-row justify-between w-full items-center">
                    <span className="text-[1.5rem] font-semibold">
                        {props.title}
                    </span>
                    <div className="p-2 ml-auto bg-slate-900 w-[50px] flex items-center justify-center text-[1.2rem] rounded-lg">
                        5
                    </div>
                </span>
                <span className="text-[1.2rem] mt-[-7.5px] text-gray-300">
                    {props.author}
                </span>

                <span className="text-[1rem] text-gray-400 text-wrap line-clamp-4 lg:line-clamp-4 xl:line-clamp-5">
                    {props.description}
                </span>
            </div>
        </Link>
    )
}