// state
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ReactCountryFlag from "react-country-flag"

import { useAuth } from '../context/AuthContext';

import BookGrid from "../components/Search/BookGrid";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";


function Search() {

    const { authenticated, login, logout, signup } = useAuth();

    const [searchPage, setSearchPage] = useState(0);
    console.log(searchPage);

    const [showLanguageMenu, setShowLanguageMenu] = useState(false);
    const [searchLanguage, setSearchLanguage] = useState("en");

    const [searchResults, setSearchResults] = useState([]);

    async function searchWithQuery() {
        const searchTerm = window.location.search.substring(3)

        if (searchTerm === "") {
            return;
        }

        //console.log(searchTerm);

        // page
        await fetch("https://www.googleapis.com/books/v1/volumes?q=novel " + searchTerm + `&maxResults=40&orderBy=relevance&printType=books&startIndex=${searchPage}&key=AIzaSyA_pi0QsRav4GtmaBPsyeaGT8VMpHlj5Jw`) // &langRestrict=${searchLanguage}
            .then(response => response.json())
            .then(data => {
                // if any dont have an isbn. not just not have an industry identifier, but specifically dont have an isbn
                // remove them from the array

                for (let i = 0; i < data.items.length; i++) {
                    if (data.items[i].volumeInfo.industryIdentifiers === undefined) {
                        data.items.splice(i, 1);
                    } else {
                        if (data.items[i].volumeInfo.industryIdentifiers[0].type !== "ISBN_13") {
                            data.items.splice(i, 1);
                        }
                    }
                }

                // if industryIdentifiers type is "OTHER"
                for (let i = 0; i < data.items.length; i++) {
                    if (data.items[i].volumeInfo.industryIdentifiers === undefined) {
                        data.items.splice(i, 1);
                    }

                    try {
                        if (data.items[i].volumeInfo.industryIdentifiers[0].type === "OTHER") {
                            data.items.splice(i, 1);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }

                // if two books have the same title and author, remove with shortest description
                for (let i = 0; i < data.items.length; i++) {
                    for (let j = 0; j < data.items.length; j++) {
                        try {
                            if (i !== j) {
                                if (data.items[i].volumeInfo.title === data.items[j].volumeInfo.title && data.items[i].volumeInfo.authors[0] === data.items[j].volumeInfo.authors[0]) {
                                    if (data.items[i].volumeInfo.description.length > data.items[j].volumeInfo.description.length) {
                                        data.items.splice(j, 1);
                                    } else {
                                        data.items.splice(i, 1);
                                    }
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }

                setSearchResults(data.items);
            }
        )

        async function addToDatabase() {
            await fetch(`https://nerded.io/api/user_actions/search_books`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    //session_token: authenticated.sessionToken,
                    query: searchTerm,
                    language: searchLanguage,
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
        }

        addToDatabase();
    }

    //console.log(window.location.search.substring(3));

    useEffect(() => {
        searchWithQuery();
    }, [window.location.search])


    function setLanguage(lang) {
        setSearchLanguage(lang);
        searchWithQuery();

        setShowLanguageMenu(false);
    }

    const [readList, setReadList] = useState([]);
    const [bookmarkedList, setBookmarkedList] = useState([]);
    const [reviewedList, setReviewedList] = useState([]);

    const [refreshBookListState, setRefreshBookListState] = useState(false);

    function refreshBookList() {
        setRefreshBookListState(!refreshBookListState);
    }

    useEffect(() => {
        async function getBookData() {
            await fetch(`https://nerded.io/api/user_actions/view_user_book_stats`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            })
            .then(response => response.json())
            .then(data => {
                try {
                    setReadList(data.books_read);
                    setBookmarkedList(data.books_bookmarked);
                    setReviewedList(data.books_reviewed);
                } catch (error) {
                    console.log(error);
                }
            })
        }

        if (authenticated.authenticated) {
            getBookData();
        }

    }, [refreshBookListState, window.location.search])

    return (
        <div>
            <Navbar showAccountPrompt={true} searchWithQuery={searchWithQuery} />

            <div className="font-normal flex flex-row flex-wrap gap-2 w-fit ml-[5rem] text-lg mt-[1rem] mb-[1rem]">
                <span>Didn't find what you're looking for?</span>
                <Link to="/bookrequest" className="font-bold text-sky-400 hover:underline cursor-pointer">
                    Make a request!
                </Link>
            </div>

            <BookGrid readList={readList} bookmarkedList={bookmarkedList} books={searchResults} reviewedList={reviewedList} refreshReviewsList={refreshBookList} />

            <div className="select-none flex flex-col items-end fixed z-10 right-0 bottom-0 text-xl cursor-pointer p-3 bg-transparent rounded">
                <div onClick={() => {showLanguageMenu ? setShowLanguageMenu(false) : setShowLanguageMenu(true)}} className="p-2 rounded-full w-min flex flex-row bg-slate-800 border justify-center border-slate-700 items-center gap-2">
                    <span className="flex items-center justify-center">
                        <span className="material-symbols-outlined">
                        flag
                        </span>
                    </span>
                </div>

                {showLanguageMenu ?
                <div className="flex flex-col gap-[10px] mt-[10px] bg-slate-900 rounded pl-2 pt-2 pb-2">
                    <div onClick={() => setLanguage("en")} className={`p-2 pr-3 rounded flex flex-row justify-between border-[1.5px] items-center gap-2 ${(searchLanguage === "en") ? "bg-emerald-700 border-emerald-500" : "bg-slate-800 border-slate-700"}`}>
                        English
                        <ReactCountryFlag countryCode="US" svg />
                    </div>

                    <div onClick={() => setLanguage("pt-BR")} className={`p-2 pr-3 rounded flex flex-row justify-between border-[1.5px] items-center gap-2 ${(searchLanguage === "pt-BR") ? "bg-emerald-700 border-emerald-500" : "bg-slate-800 border-slate-700"}`}>
                        Portuguese
                        <ReactCountryFlag countryCode="BR" svg />
                    </div>

                    <div onClick={() => setLanguage("es")} className={`p-2 pr-3 rounded flex flex-row justify-between border-[1.5px] items-center gap-2 ${(searchLanguage === "es") ? "bg-emerald-700 border-emerald-500" : "bg-slate-800 border-slate-700"}`}>
                        Spanish
                        <ReactCountryFlag countryCode="ES" svg />
                    </div>
                </div>
                : null}
            </div>

            <Footer />
        </div>
    )
}

export default Search;
