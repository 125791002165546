export default function RankBadge(props) {
    const colors = {
        "admin": "bg-red-900",
        "moderator": "bg-green-900",
        "librarian": "bg-blue-900",
        "supporter": "bg-yellow-900",
        "member": "bg-gray-700",
    }

    return (
        <div className={`rounded-lg text-[0.8rem] text-slate-50 font-semibold px-2 py-1 ${colors[props.rank]}`}>
            {props.rank.toUpperCase()}
        </div>
    )
}
