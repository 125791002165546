

export default function SimilarBookCard(props) {

    function openBookPage() {
        window.location.href = `/book?isbn=${props.isbn}`;
    }

    return (
        <div onClick={openBookPage} className="p-2 rounded-lg overflow-hidden min-w-[20rem] select-none bg-slate-800 hover:border-emerald-700 transition border-2 border-transparent cursor-pointer items-start flex gap-[16px] min-h-[6rem]">
            <img className="h-[7rem] rounded-lg select-none" src={props.image.replace("http://", "https://")} alt={props.title} />
            <div className="flex flex-col gap-[5px] py-1 select-none">
                <h3 className="text-[1.2rem] text-wrap text-slate-200 font-semibold line-clamp-2 overflow-hidden">{props.title}</h3>
                <p className="truncate overflow-hidden text-slate-300 text-[1.1rem]">{props.author}</p>
            </div>
        </div>
    )
}