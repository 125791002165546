import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import BookListCard from "../components/BookShelfElements/BookListCard";

export default function Shelves() {
    return (
        <>
            <Navbar />

            <div className="grid-new mt-[-1.5rem] pb-[2rem]">
                <div className="pt-[4.5rem] mb-[2rem] w-fit ml-auto mr-auto text-[2.2rem] text-center font-semibold font-serif">
                    Explore Our Book Genres
                </div>

                <div className="mb-[4rem] w-[50vw] text-wrap text-center ml-auto mr-auto text-[1.2rem] text-slate-400 font-thin">
                    Browse through our wide selection of book genres and discover your next literary adventure.
                </div>

                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ml-auto mr-auto gap-[0.9rem] w-[70vw] justify-center">
                    <BookListCard
                        category="Fiction"
                        text="Immerse yourself in the world of fiction with our wide selection of novels."
                    />

                    <BookListCard
                        category="Non-fiction"
                        text="Expand your knowledge with our selection of non-fiction books."
                    />

                    <BookListCard 
                        category="Young Adult" 
                        text="Engage young minds with captivating stories and coming-of-age narratives."
                    />

                    <BookListCard 
                        category="Romance" 
                        text="Indulge in tales of love, passion, and heartwarming connections."
                    />

                    <BookListCard
                        category="Poetry"
                        text="Explore the beauty of poetry with our collection of poems and poets."
                    />

                    <BookListCard 
                        category="Short Stories" 
                        text="Dive into the captivating world of short stories with our curated collection."
                    />

                    <BookListCard 
                        category="Fantasy" 
                        text="Embark on epic adventures and magical realms with our fantasy books."
                    />

                    <BookListCard 
                        category="Sci-fi" 
                        text="Journey into the future and beyond with our selection of science fiction."
                    />

                    <BookListCard 
                        category="Horror" 
                        text="Experience spine-chilling thrills and haunting tales with this selection."
                    />

                    <BookListCard 
                        category="Thriller" 
                        text="Keep on the edge of your seat with our gripping thriller novels."
                    />

                    <BookListCard 
                        category="Mystery" 
                        text="Unravel puzzles and uncover secrets with these mystery books."
                    />

                    <BookListCard 
                        category="Biography" 
                        text="Discover the lives of inspiring individuals with this collection."
                    />

                    <BookListCard 
                        category="Autobiography" 
                        text="Explore the personal journeys and reflections of notable figures."
                    />

                    <BookListCard 
                        category="History" 
                        text="Dive into the past and explore historical events, figures, and cultures."
                    />

                    <BookListCard 
                        category="Philosophy" 
                        text="Delve into profound questions and timeless wisdom with these books."
                    />

                    <BookListCard 
                        category="Self-help" 
                        text="Empower yourself with practical guides and insights for growth."
                    />

                    <BookListCard 
                        category="Cooking" 
                        text="Satisfy your curiosity and hone your cooking skills with these recipes."
                    />

                    <BookListCard 
                        category="Travel" 
                        text="Embark on adventures around the world and uncover new destinations."
                    />

                    <BookListCard 
                        category="Children" 
                        text="Spark imagination and foster a love for reading with our children's books."
                    />

                    <BookListCard 
                        category="Comics" 
                        text="Experience visual storytelling at its best with our collection of comics."
                    />

                    <BookListCard 
                        category="Manga" 
                        text="Dive into the world of Japanese comics with this manga selection."
                    />
                </div>

            </div>

            <Footer />
        </>
    )
}