function Toast(props) {
    if (props.text[0] === "" || props.text[0] === null || props.text[0] === undefined) {
        return null;
    } else {

        return (
            <div className="flex fixed right-[10px] bottom-[10px] z-30 w-fit gap-[10px] flex-row border border-slate-700 bg-slate-800 p-3 px-4 items-center rounded text-slate-300">
                {
                    (props.text[1] === "success") ?
                    <span className="material-symbols-outlined text-emerald-400">
                        check_circle
                    </span> : 
                    ((props.text[1] === "error") ?
                    <span className="material-symbols-outlined text-red-400">
                        error
                    </span> : (props.text[1] === "info") ?
                    <span className="material-symbols-outlined text-blue-400">
                        info
                    </span> : null)
                }

                <span>
                    {props.text[0]}
                </span>
            </div>
        )
    }
}

export default Toast;