//import { useAuth } from '../context/AuthContext';
/*
async function getUserInfoByToken(codeResponse) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    })

    const data = await response.json();

    return data;
}

async function verifyTokenForAudience(codeResponse) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    })

    const data = await response.json();

    return data;
}

async function createNewUserInNerdedDB(email, name, picture, googleToken, googleTokenExpiresIn, googleTokenExpiresDate, refreshToken) {
    const response = await fetch(`https://nerded.io/api/users/create_user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            name: name,
            picture: picture,
            token: googleToken,
            expires: googleTokenExpiresIn,
            expiresDate: googleTokenExpiresDate,
            refreshToken: refreshToken,
        }),
    })

    const data = await response.json();

    return data;
}

async function AuthenticationSignup(codeResponse, showToast) {
    //const { authenticated, setAuthenticated } = useAuth();
    const googleClientId = "337489570830-nclgj46aqpipl7emqics0p8j0lcgej9g.apps.googleusercontent.com"

    let userData = {
        authenticated: false,
        userId: "",
        sessionToken: "",
        name: "",
        email: "",
        picture: "",
        googleTokenInfo: {
            googleToken: "",
            googleTokenExpires: "",
            googleTokenExpiresDate: "",
            googleRefreshToken: "",
        }
    }

    let userInfo = await getUserInfoByToken(codeResponse);
    let tokenInfo = await verifyTokenForAudience(codeResponse);

    let tokenAud = tokenInfo.aud;

    const matchAudienceBool = tokenAud === googleClientId;

    if (matchAudienceBool) {

        const createNewUserDBRequestResponse = await createNewUserInNerdedDB(
            userInfo.email,
            userInfo.name,
            userInfo.picture,
            codeResponse.access_token,
            codeResponse.expires_in,
            codeResponse.expires_at,
            codeResponse.refresh_token
        );

        if (Object.keys(createNewUserDBRequestResponse).includes("success")) {


            const sessionToken = createNewUserDBRequestResponse["session_token"];
            const userId = createNewUserDBRequestResponse["user_id"];

            userData = {
                authenticated: true,
                userId: userId,
                sessionToken: sessionToken,
                name: userInfo.name,
                email: userInfo.email,
                picture: userInfo.picture,
                googleTokenInfo: {
                    googleToken: codeResponse.access_token,
                    googleTokenExpires: codeResponse.expires_in,
                    googleTokenExpiresDate: codeResponse.expires_at,
                    googleRefreshToken: codeResponse.refresh_token,
                }
            }

            localStorage.setItem("authenticated", JSON.stringify(userData));
            //localStorage.setItem("authenticated", JSON.stringify(userInfo));
            showToast("Successfully signed up!", "success");

            return userData;

            //window.location = redirect;
        } else {
            showToast("You already have an account with that email!", "error");
            return userData;
        }

    } else if (!matchAudienceBool) {
        //logoutWrapper();
        showToast("Error: something went wrong with your authentication!", "error");
        return userData;
    }
}

    //return userData;

export default AuthenticationSignup;*/

import { toast } from "sonner";

import Cookies from 'js-cookie';

async function getUserInfoByToken(codeResponse) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    });

    const data = await response.json();
    return data;
}

async function verifyTokenForAudience(codeResponse) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${codeResponse.access_token}`, {
        headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: 'application/json'
        }
    });

    const data = await response.json();
    return data;
}

async function createNewUserInNerdedDB(email, name, picture, googleToken, googleTokenExpiresIn, googleTokenExpiresDate, refreshToken) {
    const response = await fetch(`https://nerded.io/api/users/create_user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: email,
            name: name,
            picture: picture,
            token: googleToken,
            expires: googleTokenExpiresIn,
            expiresDate: googleTokenExpiresDate,
            refreshToken: refreshToken,
        }),
    });

    const data = await response.json();
    return data;
}

async function AuthenticationSignup(codeResponse, showToast) {
    const googleClientId = "337489570830-nclgj46aqpipl7emqics0p8j0lcgej9g.apps.googleusercontent.com";

    let userData = {
        authenticated: false,
        userId: "",
        sessionToken: "",
        name: "",
        email: "",
        picture: "",
        picture_is_default: true,
        picture_generation_details: {
            variant: "",
            seed: ""
        },
        googleTokenInfo: {
            googleToken: "",
            googleTokenExpires: "",
            googleTokenExpiresDate: "",
            googleRefreshToken: "",
        }
    };

    let userInfo = await getUserInfoByToken(codeResponse);
    let tokenInfo = await verifyTokenForAudience(codeResponse);

    let tokenAud = tokenInfo.aud;
    const matchAudienceBool = tokenAud === googleClientId;

    if (matchAudienceBool) {
        const createNewUserDBRequestResponse = await createNewUserInNerdedDB(
            userInfo.email,
            userInfo.name,
            userInfo.picture,
            codeResponse.access_token,
            codeResponse.expires_in,
            codeResponse.expires_at,
            codeResponse.refresh_token
        );

        if (Object.keys(createNewUserDBRequestResponse).includes("success")) {

            const sessionToken = createNewUserDBRequestResponse["session_token"];
            const userId = createNewUserDBRequestResponse["user_id"];

            console.log(createNewUserDBRequestResponse)

            userData = {
                authenticated: true,
                userId: userId,
                sessionToken: sessionToken,
                name: createNewUserDBRequestResponse.user_info.name,
                rank: createNewUserDBRequestResponse.user_info.rank,
                email: userInfo.email,
                picture: userInfo.picture,
                picture_is_default: createNewUserDBRequestResponse.user_info.picture_is_default,
                picture_generation_details: {
                    variant: createNewUserDBRequestResponse.user_info.picture_generation_details.variant,
                    seed: createNewUserDBRequestResponse.user_info.picture_generation_details.seed,
                    colors: createNewUserDBRequestResponse.user_info.picture_generation_details.colors
                },
                googleTokenInfo: {
                    googleToken: codeResponse.access_token,
                    googleTokenExpires: codeResponse.expires_in,
                    googleTokenExpiresDate: codeResponse.expires_at,
                    googleRefreshToken: codeResponse.refresh_token,
                }
            };

            // Replace localStorage with Cookies
            Cookies.set("authenticated", JSON.stringify(userData), { expires: 21 }); // Set the cookie with 7-day expiration
            toast.success("Successfully signed up!");

            return userData;

        } else {
            toast.error("You already have an account with that email!");
            return userData;
        }

    } else if (!matchAudienceBool) {
        toast.error("Something went wrong with your authentication!");
        return userData;
    }
}

export default AuthenticationSignup;
