import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useAuth } from '../../context/AuthContext';

import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer";

import { toast } from "sonner";

function CoverPuzzle() {
    const { authenticated, login, logout } = useAuth();

	const [imageSrc, setImageSrc] = useState("");
    const [seed, setSeed] = useState(0);

    // set random seed

    useEffect(() => {
        async function getRandomCover() {
            const response = await fetch("https://nerded.io/api/games/getrandomcover", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated?.userId,
                    session_token: authenticated?.sessionToken,
                })
            });

            const data = await response.json();
            console.log(data);

            if (data.requestStatus === "error") {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                toast.error(data.error);
                return;
            }

            setImageSrc(data.cover);
            setSeed(data.seed);
        }

        getRandomCover();
    }, [authenticated]);

	function PuzzlePiece({
		piece,
		index,
		onDropPiece,
		isComplete,
		pieceWidth,
		pieceHeight,
	}) {
		const [{ isDragging }, drag] = useDrag(() => ({
			type: "puzzlePiece",
			item: { index },
			collect: (monitor) => ({
				isDragging: !!monitor.isDragging(),
			}),
		}));

		const [, drop] = useDrop(() => ({
			accept: "puzzlePiece",
			drop: (item) => onDropPiece(item.index, index),
		}));

		const pieceStyle = {
			backgroundImage: `url(${imageSrc})`,
			backgroundPosition: `${piece.x * 100}% ${piece.y * 100}%`,
			backgroundSize: `${piece.totalX * 100}% ${piece.totalY * 100}%`,
			width: `${pieceWidth}px`,
			height: `${pieceHeight}px`,
            userSelect: "none",
		};

		return (
			<div
				ref={(node) => drag(drop(node))}
				className={`relative select-none bg-cover cursor-grab border-[0.5px] ${
					isDragging ? "opacity-70" : "opacity-100"
				} ${isComplete ? "border-green-500" : "border-gray-200"}`}
				style={{
					...pieceStyle,
					transition: "transform 0.2s, opacity 0.2s",
				}}
			/>
		);
	}

    function handleComplete() {

    }

	function Puzzle({ difficulty, onComplete }) {
		const [pieces, setPieces] = useState([]);
		const [isComplete, setIsComplete] = useState(false);
		const [gridSize, setGridSize] = useState(3);
		const [pieceWidth, setPieceWidth] = useState(100);
		const [pieceHeight, setPieceHeight] = useState(160);

		useEffect(() => {
			const newGridSize =
				difficulty === "easy" ? 3 : difficulty === "medium" ? 4 : 5;
			setGridSize(newGridSize);

            const screenWidth = window.innerWidth;
            const maxPieceWidth = screenWidth / newGridSize;

			const newPieceWidth = Math.min(300 / newGridSize, maxPieceWidth); // Make pieces responsive to screen size
			setPieceWidth(newPieceWidth);
			setPieceHeight(newPieceWidth * 1.6); // Set height to 1.6 times the width
			setPieces(seedableShuffle(createPuzzlePieces(newGridSize, newGridSize), seed));
			setIsComplete(false);
		}, [difficulty]);

		function handleDropPiece(draggedIndex, dropIndex) {
			setPieces((prevPieces) => {
				const newPieces = [...prevPieces];
				[newPieces[draggedIndex], newPieces[dropIndex]] = [
					newPieces[dropIndex],
					newPieces[draggedIndex],
				];

				const puzzleComplete = checkPuzzleComplete(newPieces);
				setIsComplete(puzzleComplete);
				if (puzzleComplete) {
					onComplete();
				}

				return newPieces;
			});
		}

		function checkPuzzleComplete(pieces) {
			return pieces.every(
				(piece, index) => piece.originalIndex === index,
			);
		}

		return (
			<div className="flex flex-col items-center p-2 bg-slate-800 shadow-lg">
				<div
					className="grid gap-[0px] bg-gray-700"
					style={{
						gridTemplateColumns: `repeat(${gridSize}, ${pieceWidth}px)`,
						gridTemplateRows: `repeat(${gridSize}, ${pieceHeight}px)`,
						width: `${gridSize * pieceWidth}px`,
						height: `${gridSize * pieceHeight}px`,
					}}
				>
					{pieces.map((piece, index) => (
						<PuzzlePiece
							key={index}
							piece={piece}
							index={index}
							onDropPiece={handleDropPiece}
							isComplete={isComplete}
							pieceWidth={pieceWidth}
							pieceHeight={pieceHeight}
						/>
					))}
				</div>
				{isComplete && (
					<div className="mt-4 text-green-500 font-bold">
						Puzzle Complete!
					</div>
				)}
			</div>
		);
	}

	function createPuzzlePieces(rows, cols) {
		const pieces = [];
		for (let y = 0; y < rows; y++) {
			for (let x = 0; x < cols; x++) {
				pieces.push({
					x: x / (cols - 1),
					y: y / (rows - 1),
					originalIndex: y * cols + x,
					totalX: cols,
					totalY: rows,
				});
			}
		}
		return pieces;
	}

    function seedableShuffle(array, seed) {
        const newArray = [...array];
        let randomSeed = seed;

        function random() {
            const x = Math.sin(randomSeed++) * 10000;
            return x - Math.floor(x);
        }

        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }

        return newArray;
    }

	const [difficulty, setDifficulty] = useState("easy");
	const [completedPuzzles, setCompletedPuzzles] = useState(0);

	return (
		<DndProvider backend={HTML5Backend}>
            <Navbar />

			<div className="flex flex-col items-center p-8 bg-slate-900 min-h-screen text-white">
            <h1 className="text-4xl text-slate-300 mb-[2rem] mt-[-1rem] font-bold">Cover Jigsaw</h1>

				<div className={`text-slate-400 fixed top-[5.8rem] left-[1rem] flex space-x-4 ${!authenticated.authenticated ? "mt-[3rem]" : ""}`}>
					<button
						onClick={() => setDifficulty("easy")}
                        className={`px-4 py-2 rounded ${
                            difficulty === "easy"
                                ? "bg-green-800/50 text-green-400"
                                : "bg-slate-800 text-slate-400"
                        }`}
					>
						Easy
					</button>
					<button
						onClick={() => setDifficulty("medium")}
						className={`px-4 py-2 rounded ${
                            difficulty === "medium"
                                ? "bg-green-800/50 text-green-400"
                                : "bg-slate-800 text-slate-400"
                        }`}
					>
						Medium
					</button>
					<button
						onClick={() => setDifficulty("hard")}
						className={`px-4 py-2 rounded ${
                            difficulty === "hard"
                                ? "bg-green-800/50 text-green-400"
                                : "bg-slate-800 text-slate-400"
                        }`}
					>
						Hard
					</button>
				</div>
				<Puzzle difficulty={difficulty} onComplete={handleComplete} />
			</div>

            <Footer />
		</DndProvider>
	);
}

export default CoverPuzzle;
