// AuthContext.js
/*import React, { createContext, useState, useContext } from 'react';

import HelperClearAuth from '../authentication/HelperClearAuth';

import AuthenticationLogin  from '../authentication/AuthenticationLogin';
import AuthenticationSignup from '../authentication/AuthenticationSignup';

import { useGoogleLogin, googleLogout } from '@react-oauth/google';

import Toast from '../components/Toast';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  if (localStorage.getItem("authenticated") === null) {
    HelperClearAuth()
  }

  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("authenticated")) || {
      authenticated: false,
      userId: "",
      sessionToken: "",
      name: "",
      email: "",
      picture: "",
      googleTokenInfo: {
        googleToken: "",
        googleTokenExpires: "",
        googleTokenExpiresDate: "",
        googleRefreshToken: "",
      }
    }
  );

  function useGoogleLoginWrapper(functionToCall) {
    useGoogleLogin({
      onSuccess: (codeResponse) => {
        functionToCall(codeResponse)
      },
      onError: (error) => console.log('Login Failed:', error)
    });
  }

  function useGoogleLogoutWrapper() {
    googleLogout();
  }

  const login = useGoogleLogin({
      onSuccess: async (codeResponse) => {
        const userInfo = await AuthenticationLogin(codeResponse, showToast) //useGoogleLogoutWrapper
        setAuthenticated(userInfo)
        window.location = "/home";
      },
      onError: (error) => console.log('Login Failed:', error)
  })

  const signup = useGoogleLogin({
      onSuccess: async (codeResponse) => {
        const userInfo = await AuthenticationSignup(codeResponse, showToast) //useGoogleLogoutWrapper
        setAuthenticated(userInfo)
        window.location = "/home";
      },
      onError: (error) => console.log('Signup Failed:', error)
  })

  const logout = () => {
      googleLogout();
      HelperClearAuth();
      setAuthenticated({});

      window.location.reload();

      //showToast("Successfully logged out!", "success");
  }

  const [toast, setToast] = useState([], []);

  function showToast(text, type) {
    setToast([text, type]);

    setTimeout(() => {
      setToast(["", ""]);
    }, 7000);
  }

  return (
    <AuthContext.Provider value={{ authenticated, login, logout, signup }}>
      {children}
      <Toast text={toast} />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};*/

import React, { createContext, useState, useContext } from 'react';
import Cookies from 'js-cookie'; // Import the cookie library
import HelperClearAuth from '../authentication/HelperClearAuth';

import AuthenticationLogin from '../authentication/AuthenticationLogin';
import AuthenticationSignup from '../authentication/AuthenticationSignup';

import { useGoogleLogin, googleLogout } from '@react-oauth/google';

import Toast from '../components/Toast';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  if (!Cookies.get("authenticated")) {
    HelperClearAuth();
  }

  const [authenticated, setAuthenticated] = useState(
    Cookies.get("authenticated") ? JSON.parse(Cookies.get("authenticated")) : {
      authenticated: false,
      userId: "",
      sessionToken: "",
      name: "",
      email: "",
      picture: "",
      rank: "",
      picture_is_default: true,
      picture_generation_details: {
          variant: "",
          seed: "",
          colors: []
      },
      googleTokenInfo: {
        googleToken: "",
        googleTokenExpires: "",
        googleTokenExpiresDate: "",
        googleRefreshToken: "",
      }
    }
  );

  function useGoogleLoginWrapper(functionToCall) {
    useGoogleLogin({
      onSuccess: (codeResponse) => {
        functionToCall(codeResponse);
      },
      onError: (error) => console.log('Login Failed:', error)
    });
  }

  function useGoogleLogoutWrapper() {
    googleLogout();
  }

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const userInfo = await AuthenticationLogin(codeResponse, showToast); //useGoogleLogoutWrapper
      setAuthenticated(userInfo);
      Cookies.set("authenticated", JSON.stringify(userInfo), { expires: 21 }); // Store in cookies

      const windowLocation = window.location.pathname;
      console.log(windowLocation)

      if (windowLocation === "/login" || windowLocation === "/signup") {
        window.location = "/home";
      } else {
        window.location.reload();
      }
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  const signup = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const userInfo = await AuthenticationSignup(codeResponse, showToast); //useGoogleLogoutWrapper
      setAuthenticated(userInfo);
      Cookies.set("authenticated", JSON.stringify(userInfo), { expires: 21 }); // Store in cookies

      const windowLocation = window.location.pathname;

      console.log(windowLocation)

      if (windowLocation === "/login" || windowLocation === "/signup") {
        window.location = "/home";
      } else {
        window.location.reload();
      }

    },
    onError: (error) => console.log('Signup Failed:', error)
  });

  const logout = () => {
    //window.location = "/home";
    googleLogout();
    HelperClearAuth();
    Cookies.remove("authenticated"); // Clear cookies on logout
    setAuthenticated({});
    //window.location.reload();
  };

  const [toast, setToast] = useState([], []);

  function showToast(text, type) {
    setToast([text, type]);

    setTimeout(() => {
      setToast(["", ""]);
    }, 7000);
  }

  return (
    <AuthContext.Provider value={{ authenticated, login, logout, signup }}>
      {children}
      <Toast text={toast} />
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
