/*import { useState } from "react";

export default function ShowMoreReview(props) {
    const [showMore, setShowMore] = useState(false);

    // get substring. 500 chars, snap to end of sentence
    let substring;
    const chars = props?.chars || 700;

    const text = props.text || "";

    const newlineCount = (text.match(/\n/g) || []).length;

    let removedNewlines = false;

    if (text.length < chars) {
        substring = text;

        // also remove stuff after 10th newline
        substring = substring.split('\n').slice(0, 10).join('\n');
        removedNewlines = true;

    } else {
        substring = text.substring(0, chars);

        // snap to end of sentence
        const lastPeriodIndex = substring.lastIndexOf(".");

        if (lastPeriodIndex !== -1) {
            substring = substring.substring(0, lastPeriodIndex + 1);
        }
    }

    // Function to add space after punctuation if needed
    const addSpaceAfterPunctuation = (str) => {
        const punctuation = [".", ",", "!", "?", ";", ":"];
        for (let punct of punctuation) {
            let index = str.indexOf(punct);
            while (index !== -1) {
                if (index + 1 < str.length && str[index + 1] !== " ") {
                    str = str.slice(0, index + 1) + " " + str.slice(index + 1);
                }
                index = str.indexOf(punct, index + 2); // Move to the next occurrence
            }
        }
        return str;
    };

    // Apply the function to the substring and full text
    substring = addSpaceAfterPunctuation(substring);
    const subParagraphs =  substring.split('\n');
    const fullParagraphs = text.split('\n').map(paragraph => addSpaceAfterPunctuation(paragraph));

    // based on state
    let current = showMore ? fullParagraphs : subParagraphs;

    // if props.color is not undefined, then use that color for the text
    // otherwise, use slate-400

    let color = "text-slate-300";

    if (Object.keys(props).includes("color")) {
        color = `${props.color}`;
    }

    // Split current into paragraphs by approximately 80 words to the nearest period
    current = current.join(" ").split(" ");
    let currentParagraph = "";
    let currentLength = 0;

    current = current.reduce((acc, word) => {
        if (currentLength + word.length > 1000) {
            // Find the nearest period before the character limit
            let index = currentParagraph.lastIndexOf(".");
            if (index === -1) {
                index = currentParagraph.length - 1; // If no period found, split at the character limit
            }
            acc.push(currentParagraph.slice(0, index + 1).trim()); // Push the paragraph up to the period (inclusive)

            // Prepare for the next paragraph
            currentParagraph = currentParagraph.slice(index + 1).trim();
            currentLength = currentParagraph.length;
        }

        currentParagraph += word + " ";
        currentLength += word.length + 1; // Add 1 for the space

        return acc;
    }, []);

    // Push the remaining paragraph if it exists
    if (currentParagraph.length > 0) {
        current.push(currentParagraph.trim());
    }

    return (
        <div className={`${color} flex flex-row items-center`}>

            <div>
                {current.map((paragraph, index) => {
                    return (
                        <div key={index} className="mt-3 text-[1.1rem]">
                            {paragraph}

                        </div>
                    )
                })}

                {(!text.length > chars || !removedNewlines) ? <button className="font-semibold text-emerald-500 hover:underline mt-3 rounded" onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</button> : null}
            </div>
        </div>
    );
}*/


import { useState } from "react";

export default function ShowMoreReview(props) {
    const [showMore, setShowMore] = useState(false);

    const chars = props?.chars || 700;
    const text = props.text || "";

    // Function to add space after punctuation if needed
    const addSpaceAfterPunctuation = (str) => {
        return str.replace(/([.,!?;:])(?=[^\s])/g, "$1 ");
    };

    // Clean up the text
    const cleanedText = addSpaceAfterPunctuation(text);

    // Truncate to the desired length and snap to the end of the nearest sentence
    let substring = cleanedText.length > chars ? cleanedText.slice(0, chars) : cleanedText;
    const lastPeriodIndex = substring.lastIndexOf(".");
    if (lastPeriodIndex !== -1 && lastPeriodIndex > chars - 100) {
        substring = substring.slice(0, lastPeriodIndex + 1);
    }

    // Split by newline and cap the paragraphs if needed
    const subParagraphs = substring.split('\n').slice(0, 10);
    const fullParagraphs = cleanedText.split('\n');

    // Set the color based on props
    const color = props.color ? props.color : "text-slate-300";

    // Determine the current text to show
    const current = showMore ? fullParagraphs : subParagraphs;

    // Function to split the text into paragraphs with a max word count
    const splitIntoParagraphs = (textArray, maxWords) => {
        let paragraphs = [];
        let currentParagraph = "";

        textArray.forEach((word) => {
            if ((currentParagraph.split(" ").length + word.split(" ").length) <= maxWords) {
                currentParagraph += word + " ";
            } else {
                paragraphs.push(currentParagraph.trim());
                currentParagraph = word + " ";
            }
        });

        if (currentParagraph.trim().length > 0) {
            paragraphs.push(currentParagraph.trim());
        }

        return paragraphs;
    };

    const maxWords = 80;
    const displayedParagraphs = splitIntoParagraphs(current, maxWords);

    return (
        <div className={`${color} flex flex-row items-center`}>
            <div>
                {displayedParagraphs.map((paragraph, index) => (
                    <div key={index} className="mt-3 text-[1.1rem]">
                        {paragraph}
                    </div>
                ))}

                {(text.length > chars || showMore) && (
                    <button className="font-semibold text-[1.1rem] text-emerald-500 hover:underline mt-3 rounded" onClick={() => setShowMore(!showMore)}>
                        {showMore ? "Show less" : "Show more"}
                    </button>
                )}
            </div>
        </div>
    );
}
