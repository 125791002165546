import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import RequestBookForm from "../components/BookRequests/RequestBookForm";

import { useAuth } from "../context/AuthContext";

export default function BookRequestPage() {
    const { authenticated, login, logout, signup } = useAuth();

    if (!authenticated.authenticated) {
        login();
        return;
    }

    return (
        <div className="grid-new">
            <Navbar />

            <div className="flex flex-col items-center justify-center my-[4rem] mb-[5rem]">
                <RequestBookForm />
            </div>

            <Footer />
        </div>
    )
}
