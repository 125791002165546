import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Feed from "../components/Feed"

function Home() {
    //const { authenticated, login, logout } = useAuth();
    // showAccountPrompt={!authenticated.authenticated}

    return (
        <>
            <Navbar />
            <Feed />
            <Footer />
        </>
    )
}

export default Home