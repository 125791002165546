export default function FeedStyledCard(props) {
    return (
        <div className="relative mr-auto ml-auto lg:mr-0 lg:ml-0 xl:mr-0 xl:ml-0 bg-slate-950 block px-6 py-4 mb-[3rem] rounded-lg max-w-sm" href="#">
                        
            <span className={`absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r ${props.colorL} ${props.colorM} ${props.colorR}`}></span>

            <div className="my-2 mb-3">
                <h2 className="text-white text-2xl font-bold pb-2">{props.title}</h2>
                <p className="text-gray-300 py-1">
                    {props.description}
                </p>

                <div className="w-full flex justify-center">
                    {props.children}
                </div>

            </div>
        </div>
    )
}