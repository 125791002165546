import { useAuth } from "../context/AuthContext";
import { useRef, useState } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import ChallengeDatePicker from "../components/Challenges/ChallengeDatePicker";
import { Input } from "../components/ui/input";

import { toast } from "sonner";

export default function ChallengePage() {
    const { authenticated, login, logout, signup } = useAuth();

    const numOfBooksInputRef = useRef(null);
    const challengeNameRef = useRef(null);

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const [dateChallengeDue, setDateChallengeDue] = useState(tomorrow)
    const [pointsForChallenge, setPointsForChallenge] = useState(0);

    if (!authenticated.authenticated) {
        login();
        return;
    }

    const months = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September",
        "October", "November", "December"
    ]

    /*const knownChallenges = {
        60: "60 books in a year",
        52: "52 books in 52 weeks",
        26: "26 books in 26 weeks",
        12: "12 books in 12 months",
        10: "10 books in 12 months",
        6: "6 books in 6 months",
        3: "3 Books in 3 Months"
    }*/

    function calculatePoints() {
        const numOfBooks = numOfBooksInputRef.current.value;

        if (numOfBooks <= 0) {
            setPointsForChallenge(0);
            return;
        }

        let points = numOfBooks * 10;

        if (points > 2000) {
            points = 2000;
        }

        setPointsForChallenge(points);
    }

    function jsDateToTimestamp(date) {
        // Convert a JavaScript Date object to a Unix timestamp
        return Math.floor(date.getTime() / 1000);
    }

    async function createBookChallenge() {
        if (challengeNameRef.current.value === "") {
            toast.error("Please enter a challenge name")
            return;
        }

        if (numOfBooksInputRef.current.value === "") {
            toast.error("Please enter a number of books for the challenge")
            return;
        }

        if (dateChallengeDue === null) {
            toast.error("Please select a due date for the challenge")
            return;
        }

        const response = await fetch("https://nerded.io/api/challenges/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                challenge_name: challengeNameRef.current.value,
                num_books: numOfBooksInputRef.current.value,
                challenge_due_date: jsDateToTimestamp(dateChallengeDue),
                session_token: authenticated.sessionToken,
                user_id: authenticated.userId
            })
        });

        const data = await response.json();

        if (data.requestStatus === "success") {
            toast.success("Challenge created successfully");
            window.location.href = "/challenge";
        } else {
            if (data?.relog) {
                toast.error("Please login again!");
                logout();
            }

            toast.error(`Failed to create challenge: ${data.error}`);
        }
    }

    return (
        <div className="grid-new">
            <Navbar />

            <div className="flex flex-col ml-auto mr-auto md:my-[5rem] lg:my-[5rem] xl:my-[5rem] 2xl:my-[5rem] md:mb-[10rem] lg:mb-[10rem] xl:mb-[10rem] 2xl:mb-[10rem] mb-[2rem] w-[95vw] md:w-fit lg:w-fit xl:w-fit 2xl:w-fit bg-slate-900 border-slate-700 border shadow-xl shadow-slate-950 p-[1.5rem] rounded-lg">

            <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row h-fit justify-center w-full gap-[3rem] items-start">

                <span className="flex flex-col gap-2">
                    <Input ref={challengeNameRef} placeholder="Challenge Name" className="w-[20rem] h-[3rem] rounded-md border-[1.5px] border-slate-700 bg-slate-800" />

                    <div className="flex flex-col gap-4">
                        <span className="text-[1.1rem] mt-[10px] w-fit text-wrap text-slate-400">
                            How many books do you want to read during this challenge?
                        </span>

                        <Input onChange={calculatePoints} ref={numOfBooksInputRef} placeholder="Number of Books" className="w-[20rem] h-[3rem] rounded-md border-[1.5px] border-slate-700 bg-slate-800" />
                    </div>
                </span>

                <div className="w-fit">
                    <ChallengeDatePicker setDateChallengeDue={setDateChallengeDue} />
                </div>
            </div>

            <div className="mt-[2rem] text-slate-400 text-[1.1rem] font-semibold">
                You'll get

                <span className="bg-emerald-600 mx-2 p-1 px-[0.4rem] text-slate-50 rounded">
                    {pointsForChallenge} XP points
                </span>

                for completing this challenge by {dateChallengeDue ? `${months[dateChallengeDue.getMonth()]} ${dateChallengeDue.getDate()}, ${dateChallengeDue.getFullYear()}` : "the deadline"}!
            </div>

            <div className="flex flex-row gap-4 items-center">

                <div onClick={createBookChallenge} className="select-none mt-[2rem] cursor-pointer hover:bg-emerald-900/50 bg-emerald-950/50 text-emerald-400 border border-emerald-700 px-3 py-2 w-fit rounded-lg text-[1.1rem] font-semibold">
                    Create Challenge
                </div>


                {/*{knownChallenges[numOfBooksInputRef.current?.value] ?
                <div className="mt-[2rem] border-slate-600 items-center flex border p-2 text-slate-400 hover:bg-slate-800 cursor-pointer text-[1.1rem] font-semibold w-fit rounded-lg px-3">
                    <span className="material-symbols-outlined mr-2 text-[1.4rem] font-semibold">
                        add
                    </span>

                    Join the
                    <span className="mx-1 underline">
                        {knownChallenges[numOfBooksInputRef.current?.value]}
                    </span>book club for this challenge?
                </div>
                : <span className="mt-[2rem] text-slate-400 bg-slate-800 py-2 px-3 rounded-lg w-fit text-[1.1rem] font-semibold">
                    No official book club for this challenge yet.
                </span>}*/}
            </div>

            </div>

            <Footer />
        </div>
    )
}
