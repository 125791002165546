import catImage from "../../assets/catSplash.PNG";

import ProfilePicture from "../../components/ProfilePicture";

export default function Posthead(props) {
    /*
    props = {
        name: "name",
        role: "role",
        image: "image",
        timestamp: "timestamp"
        imageShow: bool
    }
    */

    const timeAgoString = formatTimeAgo(props.timestamp);

    function formatTimeAgo(datePosted) {
        if (!datePosted) {
            return "Unknown";
        }

        // datePosted is time.time() from python
        const timeAgo = new Date(datePosted * 1000);
        const now = new Date();

        const timeDifferenceInSeconds = Math.floor((now - timeAgo) / 1000);
    
        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    
        if (timeDifferenceInSeconds < 60) {
            return rtf.format(-timeDifferenceInSeconds, 'second');
        } else if (timeDifferenceInSeconds < 3600) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 60), 'minute');
        } else if (timeDifferenceInSeconds < 86400) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 3600), 'hour');
        } else if (timeDifferenceInSeconds < 2592000) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 86400), 'day');
        } else if (timeDifferenceInSeconds < 31536000) {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 2592000), 'month');
        } else {
            return rtf.format(-Math.floor(timeDifferenceInSeconds / 31536000), 'year');
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    console.log(props)

    return (
        <div className="flex flex-row gap-3 items-center">
            {props.role !== "System" ? <div className="w-8 h-8 rounded-full bg-slate-800">
                {/*<img src={props.image} className="w-180 h-8 rounded-full" />*/}
                <ProfilePicture variant={props.variant} seed={props.seed} colors={props.colors} isDefault={props.picture_is_default} picture={props.image} size="32" className="w-180 h-8 rounded-full" />

            </div>
            : 
            <div className="w-8 h-8 rounded-full bg-slate-800">
                <img src={catImage} className="w-180 h-8 rounded-full" />
            </div>
            }

            <div className="flex flex-row items-center gap-3 text-[1.1rem]">
                <div className="text-slate-100 font-semibold">
                    {props.name}
                </div>

                <div className="text-emerald-400 font-semibold text-[0.8rem] bg-emerald-900/50 p-2 py-1 rounded">
                    {capitalizeFirstLetter(props.role)}
                </div>

                {props.pinned && (
                    <div className="hidden md:flex lg:flex xl:flex 2xl:flex material-symbols-outlined text-[1.2rem] text-slate-400 px-1">
                        push_pin
                    </div>
                )}

                <div className="text-slate-400 font-semibold text-[0.8rem]">
                    {timeAgoString}
                </div>
            </div>
        </div>
    )
}