import { useNavigate } from "react-router-dom";
import BookActionButtons from "../BookActionButtons";

function BookCard(props) {
    const usingnavigate = useNavigate();

    function navigate(url) {
        // reload when navigating to the same page
        usingnavigate(url);
        window.location.reload();
    }

    if (!props.cover) {
        return null
    }

    const year = props.year?.split("-")[0] || props.year;
    let author

    if (props.author === undefined) {
        return null
    } else {
        // add commas between authors
        author = props.author.join(", ")
    }


    return (
        <div className="border-[2px] border-transparent flex flex-col gap-2 bg-slate-800 rounded-xl p-4 w-[90vw] sm:w-[90vw] md:w-[44vw] lg:w-[29vw] h-[12rem] hover:border-emerald-500">
            <div className="flex flex-row gap-4">
                {props.cover ? <img src={props.cover} onClick={() => navigate(`/book?isbn=${props.isbn}`)} className="cursor-pointer rounded-lg w-[6.6rem] overflow-y-clip max-h-[10rem]" /> : null}
                <div className="flex flex-col h-full justify-between w-full">
                    <div className="flex flex-col gap-1 overflow-hidden">
                        <span onClick={() => navigate(`/book?isbn=${props.isbn}`)} className="cursor-pointer hover:underline text-xl font-semibold line-clamp-2">
                            {props.title}
                        </span>

                        <div onClick={() => navigate(`/search?q=${author}`)} className="cursor-pointer hover:underline text-lg w-fit text-slate-300 line-clamp-1">{author}</div>
                        <span className="text-lg text-slate-400">{year}</span>
                    </div>

                    <BookActionButtons isbn={props.isbn} bookData={props.bookData} showSendButton={false} readList={props.readList} bookmarkedList={props.bookmarkedList} booksReviewed={props.reviewedList} refreshReviewsList={props.refreshReviewsList} />

                </div>
            </div>
        </div>
    )
}

export default BookCard;