import BookActionButtons from "./BookActionButtons";
import StarRating from "./StarRating";

function Viewer(props) {

    const rating = props.rating || null;

    // get rating of a book based on reviews

    function getRating() {
        if (props.reviews.length > 0) {
            let totalRating = 0;
            let totalReviews = 0;

            props.reviews.forEach((review) => {
                totalRating += review.rating;
                totalReviews++;
            });

            return totalRating / totalReviews;
        } else {
            return null;
        }
    }

    const nerdedRating = getRating();

    let author

    if (props.author) {
        author = props.author.join(", ");
    }

    //updateHomeFeedStats only if props has it and on home page
    let updateHomeFeedStats = null;
    if (Object.keys(props).includes("updateHomeFeedStats")) {
        updateHomeFeedStats = props.updateHomeFeedStats;
    }

    return (
        <div className="flex flex-col w-full rounded-lg border-[1.5px] border-slate-700 ">
        <div className="flex flex-row w-full gap-4 p-4 rounded-t-lg bg-slate-800 text-slate-50">
            <img className="rounded-lg w-[6.6rem] h-[10rem] cursor-pointer" src={props.cover.replace("http://", "https://")} onClick={() => window.location = `/book?isbn=${props.isbn}`} />

            <div className="flex flex-col gap-[5px] w-full">
                <h1 className="text-xl font-semibold max-w-[40vw] cursor-pointer hover:underline w-fit" onClick={() => window.location = `/book?isbn=${props.isbn}`}>
                    {props.title}
                </h1>

                <p className="text-[1.2rem] text-slate-300 max-w-[40vw] cursor-pointer hover:underline w-fit" onClick={() => window.location = `/search?q=${author}`}>{author}</p>

                <p className="line-clamp-4 max-w-[40vw]">{props.description}</p>

                <div className="flex flex-row justify-between mt-4">
                    
                </div>
            </div>
        </div>

            <div className="w-full p-3 mt-[-1rem] gap-3 border-t-[1.5px] border-slate-700 bg-slate-800 flex flex-row items-center rounded-b-lg">
                {nerdedRating ?
                <div className="flex items-center">
                    <div className="star-ratings">
                        <StarRating type="viewer" rating={nerdedRating} />
                    </div>

                    <div className="flex flex-row ml-[0.55rem] gap-[2px] mt-[3px]">
                        <p className="text-sm font-medium text-gray-400 items-center flex">{nerdedRating}</p>
                        <p className="text-sm font-medium text-gray-400 items-center flex">/</p>
                        <p className="text-sm font-medium text-gray-400 items-center flex">5</p>
                    </div>

                </div>
                :
                    rating ?
                    <div className="flex items-center">
                        <div className="star-ratings">
                            <StarRating type="viewer" rating={rating} />
                        </div>

                        <div className="flex flex-row ml-[0.55rem] gap-[2px] mt-[3px]">
                            <p className="text-sm font-medium text-gray-400 items-center flex">{rating}</p>
                            <p className="text-sm font-medium text-gray-400 items-center flex">/</p>
                            <p className="text-sm font-medium text-gray-400 items-center flex">5</p>
                        </div>

                    </div>

                    : <p className="ms-1 text-sm font-medium text-gray-400">No Ratings Yet!</p>
                }

                <div className="flex ml-auto">
                    <BookActionButtons booksReviewed={props.booksReviewed} isbn={props.isbn} bookData={props.bookData} showSendButton={true} readList={props.readList} bookmarkedList={props.bookmarkedList} updateHomeFeedStats={updateHomeFeedStats} />
                </div>
            </div>
        </div>
    )
}

export default Viewer;