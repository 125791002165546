import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
// history
import { useNavigate, Link } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BookActionButtons from "../components/BookActionButtons";

import { toast } from "sonner";

import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "../components/ui/tabs-new";

import StarRating from "../components/StarRating";
import ProfilePicture from "../components/ProfilePicture";

import RankBadge from "../components/RankBadge";

function Profile() {
	const { authenticated, login, logout, signup } = useAuth();
	const Navigate = useNavigate();

	// get user from ?id= query param
	const urlParams = new URLSearchParams(window.location.search);

	let userId = urlParams.get("id");

	if (userId === null || userId === undefined) {
		if (!authenticated.authenticated) {
			Navigate("/login");
		} else {
			userId = authenticated.userId;
		}
	}

    let currentTab = urlParams.get("tab") || "read";

    function changeCurrentTab(newTab) {
        const tabList = ["read", "bookmarked", "reviewed"];

        if (!tabList.includes(newTab)) {
            return;
        }

        // push to url but don't reload page
        //Navigate(`/profile?id=${userId}&tab=${newTab}`);

        window.history.pushState({}, "", `/profile?id=${userId}&tab=${newTab}`);

        currentTab = newTab;
    }

	const [readList, setReadList] = useState([]);
	const [bookmarkedList, setBookmarkedList] = useState([]);
	const [booksReviewed, setBooksReviewed] = useState([]);

	//let readList = [];
	//let bookmarkedList = [];

	const [bookData, setBookData] = useState([]);

	const [userData, setUserData] = useState({});

	//const LSData = JSON.parse(localStorage.getItem("authenticated"));

	useEffect(() => {
		async function getBookData() {
			await fetch(
				`https://nerded.io/api/user_actions/view_user_book_stats`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: userId,
						//session_token: authenticated.sessionToken,
					}),
				},
			)
				.then((response) => response.json())
				.then((data) => {
                    if (data?.relog) {
                        toast.error("Please login again!");
                        logout();
                    }

					if (data.success) {
						// make sure none isbns are repeated
						let seen = new Set();
						let filteredBookData = data.book_data.filter((el) => {
							const duplicate = seen.has(el.isbn);
							seen.add(el.isbn);
							return !duplicate;
						});

						setReadList(data.books_read);
						setBookmarkedList(data.books_bookmarked);
						setBooksReviewed(data.books_reviewed);

						console.log("books reviewed by user")
						console.log(data.books_reviewed)

						setUserData(data.user);
                        console.log(data.user)
						//readList = data.books_read;
						//console.log("GETTING READLIST FROM API");
						//console.log(data.books_read);

						try {
							setBookData([...filteredBookData]);
						} catch (error) {
							console.log(error);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});

			/*await fetch(`https://nerded.io/api/user_actions/view_bookmarked_list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data.books_bookmarked);
                    setBookmarkedList(data.books_bookmarked);
                    //bookmarkedList = data.books_bookmarked;
                }

                setBookData([...data.book_data])
            })
            .catch(error => {
                console.log(error);
            });*/
		}

		getBookData();
	}, [window.location.href]);

	const [usersReadList, setUsersReadList] = useState([]);
	const [usersBookmarkedList, setUsersBookmarkedList] = useState([]);
	const [usersBooksReviewed, setUsersBooksReviewed] = useState([]);
	const [usersBookReviews, setUsersBookReviews] = useState([]);

	// USER's INFO, not profile's...
	useEffect(() => {
		async function getUsersBookData() {
			await fetch(
				`https://nerded.io/api/user_actions/view_user_book_stats`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						user_id: authenticated.userId,
						//session_token: authenticated.sessionToken,
					}),
				},
			)
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						// make sure none isbns are repeated
						let seen = new Set();
						let filteredBookData = data.book_data.filter((el) => {
							const duplicate = seen.has(el.isbn);
							seen.add(el.isbn);
							return !duplicate;
						});

						//console.log(data.books_read);
						setUsersReadList(data.books_read);
						setUsersBookmarkedList(data.books_bookmarked);
						setUsersBooksReviewed(data.books_reviewed);
						setUsersBookReviews(data.books_reviewed);

						console.log("books reviewed")
						console.log(data.books_reviewed);
						console.log(usersBookReviews);

						//readList = data.books_read;
						//console.log("GETTING READLIST FROM API");
						//console.log(data.books_read);
					}
				})
				.catch((error) => {
					console.log("failed getting users book data")
					console.log(error);
				});

			/*await fetch(`https://nerded.io/api/user_actions/view_bookmarked_list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log(data.books_bookmarked);
                    setBookmarkedList(data.books_bookmarked);
                    //bookmarkedList = data.books_bookmarked;
                }

                setBookData([...data.book_data])
            })
            .catch(error => {
                console.log(error);
            });*/
		}

		if (authenticated.authenticated) {
			getUsersBookData();
		}
	}, [window.location.href]);

	//console.log(bookData);
	//console.log(readList);
	//console.log(bookmarkedList);

	const [following, setFollowing] = useState(false);

	async function followUser() {
		console.log(userData.id);

		await fetch(`https://nerded.io/api/social/follow_user`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: authenticated.userId,
				session_token: authenticated.sessionToken,
				user_to_follow_id: userId,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

				if (data.status === "success") {
					return true;
				}
			})
			.catch((error) => {
				return false;
			});
	}

	async function unfollowUser() {
		//console.log(userData.id);

		await fetch(`https://nerded.io/api/social/unfollow_user`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: authenticated.userId,
				session_token: authenticated.sessionToken,
				user_to_follow_id: userId,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

				if (data.status === "success") {
					return true;
				}
			})
			.catch((error) => {
				return false;
			});
	}

	const [followerCount, setFollowerCount] = useState(0);
	const [followingCount, setFollowingCount] = useState(0);

	useEffect(() => {
		setFollowing(false);
		setFollowerCount(0);
		setFollowingCount(0);

	}, [window.location.href]);

	async function handleFollow() {
		if (!following) {
			setFollowing(true);
			await followUser();
			toast.success("Successfully followed user");

			setFollowerCount(followerCount + 1);
		} else {
			// unfollow user
			setFollowing(false);
			await unfollowUser();
			toast.success("Successfully unfollowed user");

			setFollowerCount(followerCount - 1);
		}
	}

	useEffect(() => {
		async function loadUserSocialStats() {
			await fetch(
				`https://nerded.io/api/social/get_user_social_stats`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						target_id: userId,
						user_id: authenticated.userId,
						session_token: authenticated.sessionToken,
					}),
				},
			)
				.then((response) => response.json())
				.then((data) => {
                    if (data?.relog) {
                        toast.error("Please login again!");
                        logout();
                    }

					//console.log(data)
					setFollowerCount(data.data.follower_count);
					setFollowingCount(data.data.following_count);

					setFollowing(data.data.amIFollowing);
				})
				.catch((error) => {
					console.log(error);
				});
		}

		loadUserSocialStats();
	}, [window.location.pathname, userId]);

	function copyReviewLink(link) {
		navigator.clipboard.writeText(link);
		toast.success("Copied review link to clipboard");
	}

	console.log("user data")
	console.log(userData);

    const leagueMappings = {
        "unranked": "Unranked",
        "bronze": "Bronze",
        "silver": "Silver",
        "gold": "Gold",
        "platinum": "Platinum",
        "diamond": "Diamond",
        "master": "Master",
        "legend": "Legend"
    }

	return (
		<div>
			<Navbar showAccountPrompt={false} />

			<div className="flex flex-col gap-2 w-[75vw] ml-auto mr-auto h-fit mb-[2rem] mt-[2rem]">
				<div className="flex flex-row items-center gap-3">
					{userData.name ? <div className="flex flex-row gap-[16px] w-fit h-fit items-center bg-slate-800 p-2 pr-[1.35rem] rounded-full">
						{/*<img
							src={userData.picture}
							referrerPolicy="no-referrer"
							className="rounded-full w-[40px] h-[40px]"
						/>*/}

						<ProfilePicture className={"rounded-full w-[40px] h-[40px]"} size={40} seed={userData?.picture_generation_details?.seed} variant={userData?.picture_generation_details?.variant} isDefault={userData?.picture_is_default} picture={userData?.picture} colors={userData?.picture_generation_details?.colors} />

						<div className="flex flex-row gap-[1rem] items-center">
							<span className="text-[1.4rem] w-fit">
								{userData.name}
							</span>

							{userData.rank ?
                                <RankBadge rank={userData.rank} />
                            : null}
						</div>
					</div> : null}

                    {userId !== authenticated.userId && (
                        <div
                            onClick={handleFollow}
                            className={`px-4 hidden md:flex lg:flex xl:flex 2xl:flex transition rounded-md cursor-pointer py-2 ${
                                !following
                                    ? 'bg-slate-800 hover:bg-slate-700/70'
                                    : 'bg-emerald-900/60 hover:bg-emerald-900 text-emerald-400'
                            }`}
                        >
                            {!following ? 'Follow' : 'Following'}
                        </div>
                    )}

					{/*<div className="">
                        <ReadyDialog />
                    </div>*/}

					{/*<span className="px-[12px] py-[8px] bg-slate-900 hover:text-emerald-400 rounded-lg cursor-pointer">
                        Level -1
                    </span>

                    <span className="ml-auto cursor-pointer text-lg h-fit text-emerald-400 hover:border-indigo-500 hover:text-indigo-400 rounded-full border-2 bg-slate-900 border-emerald-500 w-fit p-2 px-4">
                        @professionalbozo
                    </span>*/}
				</div>
			</div>

			<div className="w-[75vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] ml-auto mr-auto mb-[2rem]">
				<div className="gap-[2rem] w-full flex-wrap grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-2">
					<div className="flex flex-row gap-6 items-center bg-slate-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							collections_bookmark
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">Books Read</span>
							<span className="text-2xl">{readList.length}</span>
						</span>
					</div>

					{/*<div className="flex flex-col gap-2 bg-slate-800 rounded-lg w-full py-4 px-6">

                        <span className="flex flex-col gap-y-2">
                            <span className="text-slate-400">Favorite Genre</span>
                            <span className="text-2xl">
                                Fiction
                            </span>
                        </span>

                    </div>*/}

					{/*<div className="flex flex-col gap-2 bg-slate-800 rounded-lg w-full py-4 px-6">

                        <span className="flex flex-col gap-y-2">
                            <span className="text-slate-400">Favorite Author</span>
                            <span className="text-2xl">
                                Marie Lu
                            </span>
                        </span>

                    </div>*/}

					<div className="flex flex-row gap-6 items-center bg-slate-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							demography
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">Followers</span>
							<span className="text-2xl">{followerCount}</span>
						</span>
					</div>

					<div className="flex flex-row gap-6 items-center bg-slate-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							contacts
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">Following</span>
							<span className="text-2xl">{followingCount}</span>
						</span>
					</div>

					<div className="flex flex-row gap-5 items-center bg-slate-800 rounded-lg w-full py-4 px-6">
						<span className="material-symbols-outlined text-[3rem] text-slate-400 -ml-[0.2rem]">
							emoji_events
						</span>

						<span className="flex flex-col gap-y-2">
							<span className="text-slate-400">League</span>
							<span className="text-2xl flex flex-row items-center truncate">
								{userData.league ? leagueMappings[userData.league] : "N/A"}
							</span>
						</span>
					</div>
				</div>
			</div>

			{/*<div className="p-5 w-[75vw] rounded-lg bg-slate-800 ml-auto mr-auto overflow-scroll mb-[2rem]">

                <div className="flex flex-row flex-wrap w-fit gap-3 justify-center items-center">
                    <div className="flex flex-row bg-slate-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Duet Dueler
                        </span>
                    </div>

                    <div className="flex flex-row bg-slate-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img4.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Fiction Fanatic
                        </span>
                    </div>

                    <div className="flex flex-row bg-slate-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img3.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Dystopian Devotee
                        </span>
                    </div>

                    <div className="flex flex-row bg-slate-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img3.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Romance Rookie
                        </span>
                    </div>

                    <div className="flex flex-row bg-slate-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img2.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Mystery Master
                        </span>
                    </div>

                    <div className="flex flex-row bg-slate-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img2.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Manga Maniac
                        </span>
                    </div>

                    <div className="flex flex-row bg-slate-900 p-2 pr-[1rem] rounded-[20px] gap-3 min-w-fit items-center h-min">
                        <img src="img3.png" className="w-[60px] h-[60px] rounded-[20px]" />
                        <span className="text-[1.2rem]">
                            Legendary Librarian
                        </span>
                    </div>

                </div>
            </div>*/}

			<div className="flex justify-center flex-row mb-[2rem] md:w-[75vw] mx-auto lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] min-w-[95vw]">
				<Tabs defaultValue={currentTab} className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] mx-auto">
					<TabsList className="mb-4">
						<TabsTrigger onClick={() => changeCurrentTab("read")} value="read" className="text-[1rem]">
							Read
						</TabsTrigger>

						<TabsTrigger
							value="bookmarked"
							className="text-[1rem]"
                            onClick={() => changeCurrentTab("bookmarked")}
						>
							Bookmarks
						</TabsTrigger>

						<TabsTrigger value="reviewed" className="text-[1rem]" onClick={() => changeCurrentTab("reviewed")}>
							Reviews
						</TabsTrigger>

						{/*<TabsTrigger
							value="books_not_finished"
							className="text-[1rem]"
						>
							Books Not Finished
						</TabsTrigger>*/}
					</TabsList>

					<TabsContent value="read">
						{readList.length === 0 ? (
							<div className="px-3 py-5 rounded-lg gap-2 bg-slate-900 border-2 border-slate-700 flex items-center justify-center">
								<span className="material-symbols-outlined text-slate-300">
									error
								</span>

								<span className="text-slate-300">
									No books read yet
								</span>
							</div>
						) : (
						<div className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] text-slate-300 ml-auto mr-auto flex flex-col gap-2 p-3 rounded-lg bg-slate-800 mb-[5rem]">
							<span className="text-xl pt-2 pl-3 text-slate-200">
								Read Books
							</span>

							<div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1  p-2 gap-[1rem] flex-wrap">

								{bookData.map((book, index) => {
									if (!readList.includes(book.isbn)) {
										return null;
									}

									return (
										<div
											key={index}
											className="flex flex-row gap-[1rem] p-3 bg-slate-900 w-full rounded-lg"
										>
											<img
												src={book.cover}
												className="w-[8rem] rounded-lg cursor-pointer"
												onClick={() =>
													(window.location = `/book?isbn=${book.isbn}`)
												}
											/>

											<div className="flex flex-col w-full gap-1">
												<span
													className="text-xl font-semibold text-slate-200 w-fit hover:underline cursor-pointer"
													onClick={() =>
														(window.location = `/book?isbn=${book.isbn}`)
													}
												>
													{book.title}
												</span>

												<span
													className="text-lg cursor-pointer hover:underline w-fit"
													onClick={() =>
														(window.location = `/search?q=${book.author}`)
													}
												>
													{book.author}
												</span>

												<div className="flex justify-end h-full items-end">
													<BookActionButtons
														isbn={book.isbn}
														showSendButton={true}
														readList={usersReadList}
														bookmarkedList={
															usersBookmarkedList
														}
														booksReviewed={
															usersBooksReviewed
														}
														bookData={{
															title: book.title,
															industryIdentifiers:
																[
																	{
																		identifier:
																			book.isbn,
																	},
																],
														}}
													/>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						)}
					</TabsContent>

					<TabsContent value="bookmarked">
						{bookmarkedList.length === 0 ? (
							<div className="px-3 py-5 rounded-lg gap-2 bg-slate-900 border-2 border-slate-700 flex items-center justify-center">
								<span className="material-symbols-outlined text-slate-300">
									error
								</span>

								<span className="text-slate-300">
									No books bookmarked yet
								</span>
							</div>
						) : (
						<div className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] text-slate-300 ml-auto mr-auto flex flex-col gap-2 p-3 rounded-lg bg-slate-800 mb-[5rem]">
							<span className="text-xl pt-2 pl-3 text-slate-200">
								Bookmarked Books
							</span>

							<div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1 p-2 gap-[1rem] flex-wrap">
								{bookData.map((book, index) => {
									if (!bookmarkedList.includes(book.isbn)) {
										return null;
									}

									return (
										<div
											key={index}
											className="flex flex-row gap-[1rem] p-3 bg-slate-900 w-full rounded-lg"
										>
											<img
												src={book.cover}
												className="w-[8rem] rounded-lg cursor-pointer"
												onClick={() =>
													(window.location = `/book?isbn=${book.isbn}`)
												}
											/>

											<div className="flex flex-col w-full gap-1">
												<span
													className="text-xl font-semibold text-slate-200 w-fit hover:underline cursor-pointer"
													onClick={() =>
														(window.location = `/book?isbn=${book.isbn}`)
													}
												>
													{book.title}
												</span>
												<span
													className="text-lg cursor-pointer hover:underline w-fit"
													onClick={() =>
														(window.location = `/search?q=${book.author}`)
													}
												>
													{book.author}
												</span>

												<div className="flex justify-end h-full items-end">
													<BookActionButtons
														isbn={book.isbn}
														showSendButton={true}
														readList={usersReadList}
														bookmarkedList={
															usersBookmarkedList
														}
														booksReviewed={
															usersBooksReviewed
														}
														bookData={{
															title: book.title,
															industryIdentifiers:
																[
																	{
																		identifier:
																			book.isbn,
																	},
																],
														}}
													/>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						)}
					</TabsContent>

					<TabsContent value="reviewed">
						{booksReviewed.length === 0 ? (
							<div className="px-3 py-5 rounded-lg gap-2 bg-slate-900 border-2 border-slate-700 flex items-center justify-center">
								<span className="material-symbols-outlined text-slate-300">
									error
								</span>

								<span className="text-slate-300">
									No books reviewed yet
								</span>
							</div>
						) : (
						<div className="w-[95vw] md:w-[75vw] lg:w-[75vw] xl:w-[75vw] 2xl:w-[75vw] text-slate-300 ml-auto mr-auto flex flex-col gap-2 p-3 rounded-lg bg-slate-800 mb-[5rem]">
							<span className="text-xl pt-2 pl-3 text-slate-200">
								Reviewed Books
							</span>

							<div className="grid md:grid-cols-1 lg:grid-cols-1 grid-cols-1 p-2 gap-[1rem] flex-wrap">
								{booksReviewed.map((book, index) => {
									return (
										<div
											key={index}
											className="flex flex-col gap-[1rem] p-3 bg-slate-900 w-full rounded-lg"
										>
											<div className="flex-row gap-[1rem] w-full">
												<div className="flex flex-row flex-wrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap gap-4 w-full">
													<div className="flex gap-2 flex-col max-w-full lg:max-w-[50%] xl:max-w-[50%] 2xl:max-w-[50%] w-full">
														<div className="flex flex-row ml-2 gap-[6px] items-center">
															<span
																className="text-[1.3rem] truncate font-semibold py-1 text-slate-200 max-w-[66%] hover:underline cursor-pointer"
																onClick={() =>
																	(window.location = `/book?isbn=${book.isbn}`)
																}
															>
																{
																	book.book_title
																}
															</span>

															<span className="text-[1.3rem] truncate mb-[-1px] w-fit">
																{
																	book.book_author
																}
															</span>
														</div>

														<span className="text-slate-200 border-2 border-slate-700 w-full h-full bg-slate-800/50 rounded-lg p-3">
															{book.review ? book.review : <span className="px-2 py-1 rounded-lg bg-slate-700 mb-[-8px] w-fit ml-[-2px]">No review</span>}
														</span>

														<div className="flex mt-auto select-none flex-row gap-[8px] rounded-lg p-2">
															{/*<div
																className="flex items-center gap-[7px] -mr-[5px] justify-center p-2 cursor-pointer text-slate-500 hover:bg-slate-800 rounded-lg"
															>
																<span className="material-symbols-outlined ml-[-1px] mr-[1px] mt-[1px]">
																	favorite
																</span>
																<span className="flex items-center align-middle mt-[1px] mr-[2px]">
																	1
																</span>
															</div>*/}

															<Link to={`/review?isbn=${book.isbn}&id=${book.review_id}`} className="flex items-center justify-center p-2 px-[9px] cursor-pointer text-slate-500 hover:bg-slate-800 rounded-lg">
																<span className="material-symbols-outlined">
																	expand_all
																</span>
															</Link>

															<div onClick={() => copyReviewLink(`https://nerded.io/review?isbn=${book.isbn}&id=${book.review_id}`)} className="flex items-center justify-center p-2 cursor-pointer text-slate-500 hover:bg-slate-800 rounded-lg">
																<span className="material-symbols-outlined">
																	link
																</span>
															</div>

															{/*<div className="flex items-center justify-center p-2 cursor-pointer text-slate-500 hover:bg-slate-800 rounded-lg">
																<span className="material-symbols-outlined">
																	flag
																</span>
															</div>*/}

															<div className="ml-auto flex items-center rounded-lg w-fit mr-1">
																<StarRating
																	type="yellow-non-viewer"
																	rating={
																		book.rating
																	}
																/>
															</div>
														</div>
													</div>

													<div className="bg-slate-900 h-fit w-full border-2 border-slate-700 rounded-lg p-3 flex flex-row gap-[1.5rem]">
														<img
															src={
																book.book_cover
															}
															className="w-[8rem] h-[12rem] rounded-lg cursor-pointer"
															onClick={() =>
																(window.location = `/book?isbn=${book.isbn}`)
															}
														/>

														<div className="flex flex-col w-full">
															<span
																className="text-xl font-semibold text-slate-200 w-fit hover:underline cursor-pointer"
																onClick={() =>
																	(window.location = `/book?isbn=${book.isbn}`)
																}
															>
																{
																	book.book_title
																}
															</span>
															<span
																className="text-lg mt-1 cursor-pointer hover:underline w-fit"
																onClick={() =>
																	(window.location = `/search?q=${book.book_author}`)
																}
															>
																{
																	book.book_author
																}
															</span>

															<div className="flex justify-end h-full items-end">
																<BookActionButtons
																	isbn={
																		book.isbn
																	}
																	showSendButton={
																		true
																	}
																	readList={
																		usersReadList
																	}
																	bookmarkedList={
																		usersBookmarkedList
																	}
																	booksReviewed={
																		usersBooksReviewed
																	}
																	bookData={{
																		title: book.title,
																		industryIdentifiers:
																			[
																				{
																					identifier:
																						book.isbn,
																				},
																			],
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						)}
					</TabsContent>

					<TabsContent value="books_not_finished">
						<span>{readList.length} books read</span>
					</TabsContent>
				</Tabs>
			</div>

			<Footer />
		</div>
	);
}

export default Profile;
