import AvWrapper from "../components/AvWrapper";
import { useAuth } from "../context/AuthContext";

export default function ProfilePicture(props) {
    const { authenticated, login, logout } = useAuth();

    const className = props.className;
    let size = props.size;

    //if (authenticated.authenticated) {
    if (props.isDefault) {
        return (
            <img src={props.picture} referrerPolicy="no-referrer" className={className} />
        );
    } else {
        return (
            <AvWrapper
                size={size}
                name={props.seed}
                variant={props.variant} // beam marble (default), beam, pixel,sunset, ring, bauhaus # allow user to pick colors and type
                square={true}
                colors={props.colors}
                className={className}
            />
        )
    }
}