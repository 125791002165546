import { Link } from 'react-router-dom';

export default function BookListCard(props) {
    return (
        <Link to={`/bookshelf/${props.category.toLowerCase()}`} className="cursor-pointer justify-center rounded-lg bg-slate-800 transition border-2 border-transparent hover:border-slate-600 hover:bg-slate-900 flex p-4 flex-col group">
            <div className="flex flex-row justify-between items-center">
                <span className="font-semibold">
                    {props.category}
                </span>
                <div className="flex flex-row gap-2 text-gray-400 p-1 mt-[-0.25rem] mr-[-0.25rem] rounded transition-all group-hover:-rotate-45 group-hover:scale-110">
                    <span className="material-symbols-outlined">
                        arrow_forward
                    </span>
                </div>
            </div>

            <span className="text-slate-400 mt-[5px]">
                {props.text}
            </span>
        </Link>
    )
}