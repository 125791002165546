import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useAuth } from "../context/AuthContext";

import Viewer from "./Viewer";
import Chip from "./Chip";
import Input from "./Input";

import RecommendedCarousel from "./Feed/RecommendedCarousel";
import FeedStyledCard from "./Feed/FeedStyledCard";

import ProfilePicture from "./ProfilePicture";

import ChallengeCardSm from "./ChallengeCardSm";

import { toast } from "sonner";

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
  } from "./ui/carousel"

function Feed() {

    const { authenticated, login, logout, signup } = useAuth();

    const [booksReadNum, setBooksReadNum] = useState("...");
    const [bookmarksNum, setBookmarksNum] = useState("...");
    const [reviewsNum, setReviewsNum] = useState("...");

    //const [bookData, setBookData] = useState([]);
    const [readList, setReadList] = useState([]);
    const [bookmarkedList, setBookmarkedList] = useState([]);
    const [booksReviewed, setBooksReviewed] = useState([]);

    const [updateHomeFeedStatsState, setUpdateHomeFeedStatsState] = useState(false);

    function updateHomeFeedStats() {
        // check if the user is on the home page
        if (window.location.pathname === "/home") {
            // if so, update the home feed stats
            setUpdateHomeFeedStatsState(!updateHomeFeedStatsState);
        }
    }

    useEffect(() => {
        async function getUserBookStats() {
            await fetch(`https://nerded.io/api/user_actions/view_user_book_stats`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                //console.log("user stats");
                //console.log(data);
                setBooksReadNum(data.books_read.length);
                setBookmarksNum(data.books_bookmarked.length);
                setReviewsNum(data.books_reviewed.length);

                setReadList(data.books_read);
                setBookmarkedList(data.books_bookmarked);
                setBooksReviewed(data.books_reviewed);

                console.log(data.books_reviewed);
            })
            .catch(err => console.log(err))
        }

        if (authenticated.authenticated) {
            //console.log(authenticated);
            getUserBookStats();
        }

    }, [authenticated.authenticated, updateHomeFeedStatsState])

    // trending books

    const [trendingBooks, setTrendingBooks] = useState([]);
    const [trendingReviews, setTrendingReviews] = useState([]);

    useEffect(() => {
        const getTrendingBooksAndReviews = async () => {
            try {
                const response = await fetch(`https://nerded.io/api/trending/get`);
                const data = await response.json();

                setTrendingBooks(data.trending_books);
                setTrendingReviews(data.trending_reviews);
            } catch (err) {
                console.error('Failed to fetch trending books and reviews:', err);
            }
        };

        getTrendingBooksAndReviews();
    }, []);

    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const [streakInfo, setStreakInfo] = useState({
        current_streak: 0,
        longest_streak: 0,
        last_updated: 0,
        date_longest_streak_started: 0,
        date_current_streak_started: 0
    });

    const [levelingData, setLevelingData] = useState({
        level: 0,
        experience: 0,
        experience_to_next_level: 0,
        current_league: "unranked",
        highest_league: "unranked",
        current_rank: 0,
    });

    useEffect(() => {
        async function getStreakData() {
            await fetch("https://nerded.io/api/streaks/view_user_streak", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.requestStatus === "error") {
                    if (data?.relog) {
                        toast.error("Please login again!");
                        logout();
                    }

                    //toast.error(data.error);
                    console.log(data.error);
                    return;
                }

                setStreakInfo(data.streaks);
                setLevelingData(data.leveling);
            })
        }

        if (authenticated.authenticated) {
            getStreakData();
        }

    }, [authenticated.userId, authenticated.sessionToken]);

    const [activeChallenges, setActiveChallenges] = useState([]);
    const [completedChallenges, setCompletedChallenges] = useState([]);

    const [anyActiveChallenges, setAnyActiveChallenges] = useState(false);

    useEffect(() => {
        async function fetchChallenges() {
            await fetch("https://nerded.io/api/challenges/view", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_id: authenticated.userId,
                    session_token: authenticated.sessionToken,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data?.relog) {
                    toast.error("Please login again!");
                    logout();
                }

                setActiveChallenges(data.challenges_active);
                setCompletedChallenges(data.challenges_ended);

                if (data.challenges_active.length > 0) {
                    if (data.challenges_active[0].challenge_due_date * 1000 < Date.now()) {
                        setAnyActiveChallenges(false);
                    }
                    else {
                        setAnyActiveChallenges(true);
                    }
                }
            })
            .catch((error) => {
                toast.error("Failed to fetch challenges");
            });
        }

        if (authenticated.authenticated) {
            fetchChallenges();
        }
    }, [authenticated]);

    return (
        <div className="flex items-center flex-col gap-[1.5rem] pt-[1rem]">

            <div className="flex flex-row overflow-y-scroll w-[90vw] mb-[1rem] h-fit">
                <Carousel className="lg:w-[80%] md:w-[80%] w-[70%] ml-auto mr-auto">
                    <CarouselContent className="">

                        <CarouselItem className="basis-1/10">
                            <Chip name="Fiction" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Non-fiction" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Poetry" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Short Stories" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Fantasy" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Sci-fi" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Romance" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Horror" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Mystery" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Thriller" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Biography" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Autobiography" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="History" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Philosophy" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Self-help" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Cooking" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Travel" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Children" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Young Adult" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Comics" />
                        </CarouselItem>

                        <CarouselItem className="basis-1/10">
                            <Chip name="Manga" />
                        </CarouselItem>


                    </CarouselContent>

                    <CarouselPrevious className="rounded mt-[-12px]" />
                    <CarouselNext className="rounded" />

                </Carousel>

            </div>

            <div className="md:grid lg:grid xl:grid xl:grid-cols-7 mx-[1.2rem] lg:grid-cols-7 md:grid-cols-7 sm:grid-cols-1 gap-[2rem] md:gap-[1rem] lg:gap-[2rem] xl:gap-[2rem] 2xl:gap-[2rem] max-w-[75rem]">

                <div className="w-full col-span-2">
                    <div className="flex items-center flex-col">
                        <div className="flex flex-col gap-[0.8rem] p-[10px] bg-slate-800 rounded-lg w-full border-[1.5px] border-slate-700">

                            <div className="flex flex-row justify-around gap-2 w-full flex-nowrap">
                                {booksReadNum !== "..." ?
                                <Link to="/profile?tab=read" className="flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
                                    <span className="w-max">
                                        Books Read
                                    </span>
                                    <span className="text-[1.3rem]">
                                        {booksReadNum}
                                    </span>
                                </Link> :
                                <div className="flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
                                    <span className="w-max">
                                        Books Read
                                    </span>
                                    <span className="text-[1.3rem]">
                                        {booksReadNum}
                                    </span>
                                </div>
                                }

                                {bookmarksNum !== "..." ?
                                <Link to="/profile?tab=bookmarked" className="flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
                                    <span className="w-max">
                                        To Be Read
                                    </span>
                                    <span className="text-[1.3rem]">
                                        {bookmarksNum}
                                    </span>
                                </Link>
                                :
                                <div className="flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
                                    <span className="w-max">
                                        To Be Read
                                    </span>
                                    <span className="text-[1.3rem]">
                                        {bookmarksNum}
                                    </span>
                                </div>
                                }

                                {reviewsNum !== "..." ?
                                <Link to="/profile?tab=reviewed" className="md:hidden lg:flex sm:flex xl:flex flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
                                    <span className="w-max">
                                        Reviews
                                    </span>
                                    <span className="text-[1.3rem]">
                                        {reviewsNum}
                                    </span>
                                </Link>
                                :
                                <div className="md:hidden lg:flex sm:flex xl:flex flex p-2 rounded-md flex-col text-[1rem] w-1/3 items-center">
                                    <span className="w-max">
                                        Reviews
                                    </span>
                                    <span className="text-[1.3rem]">
                                        {reviewsNum}
                                    </span>
                                </div>
                                }
                            </div>

                            {authenticated.authenticated ? <div className="flex flex-row gap-2 border-t border-t-slate-700 pt-[1rem]">
                                <Link to="/streak" className="flex flex-row items-center gap-2 md:hidden lg:flex xl:flex 2xl:flex w-min text-[1.15rem] text-cyan-500 bg-cyan-950 border-2 border-cyan-800 px-[14px] p-3 rounded-lg">
                                    <span className="material-symbols-outlined text-[1.5rem] mt-[-3px]">
                                        local_fire_department
                                    </span>
                                </Link>

                                <Link to="/streak" className="flex flex-row items-center w-full gap-3 text-[1.15rem] text-cyan-500 bg-cyan-950 border-2 border-cyan-800 p-3 rounded-lg">
                                    <div className="flex flex-row justify-between w-full">
                                        <span className="decoration-wavy decoration-2">
                                            Streaks
                                        </span>
                                    </div>

                                    <span className="flex min-w-fit break-words">
                                        {streakInfo.current_streak} days
                                    </span>
                                </Link>
                            </div> : null}

                        </div>

                        {/*{authenticated.authenticated ? <div className="flex flex-col gap-[0.8rem] p-[10px] mt-6 bg-slate-800 rounded-lg w-full border-[2px] border-slate-700">
                            <div className="flex rounded-md flex-row w-full justify-between text-[1rem] items-center">

                                <span className="font-semibold text-[1.1rem] ml-2">
                                    Current Streak
                                </span>

                                <div className="flex flex-row items-center gap-2 bg-slate-950 rounded-lg px-3 py-2">
                                    <span className="material-symbols-outlined text-[1.8rem] text-emerald-500">
                                        local_fire_department
                                    </span>

                                    <span className="text-[1.3rem] mr-1">
                                        {streakInfo.current_streak}
                                    </span>
                                </div>
                            </div>
                        </div> : null}*/}

                        {authenticated.authenticated ? <div className="w-full">
                            {anyActiveChallenges ?
                                <div className="mt-5 w-full">
                                    {activeChallenges.map((challenge) => {
                                        let jsEndDate = new Date(challenge.challenge_due_date * 1000);
                                        if (jsEndDate >= Date.now()) {
                                            return (
                                                <ChallengeCardSm
                                                    name={challenge.challenge_name}
                                                    booksRead={challenge.books_read}
                                                    challengeTotal={challenge.num_books}
                                                    endDate={challenge.challenge_due_date}
                                                    id={challenge._id}
                                                />
                                            )
                                        }
                                    })}
                                </div>
                            :
                                <div className="flex text-center items-center flex-row gap-[0.4rem] p-[10px] mt-6 bg-slate-800 rounded-lg w-full justify-center border-[2px] border-slate-700">
                                    <span className="material-symbols-outlined mb-[-1px] text-[1.4rem] text-slate-200">
                                        info
                                    </span>

                                    <span>No active challenges</span>
                                </div>
                            }
                        </div> : null}

                        <div className="hidden md:flex lg:flex xl:flex flex-col space-y-4 items-center mt-[2rem] w-full">
                        <span>
                            Trending Reviews
                        </span>

                        {trendingReviews.map((item) => {
                            console.log(item);

                            return (
                                <Link to={`/review?isbn=${item.data.volumeInfo.industryIdentifiers[0].identifier}&id=${item.review.review_id}`} key={item.review.review_id} className="flex flex-col p-4 gap-3 bg-slate-800 transition hover:border-slate-600 border-[1.5px] border-slate-700 rounded-lg w-full">
                                    <span className="flex flex-row gap-3 items-center">
                                        {/*<img src={item.review.user_picture.replace("http://", "https://")} referrerPolicy="no-referrer" className="w-[2rem] h-[2rem] rounded-full" />*/}
                                        <ProfilePicture className="w-[2rem] h-[2rem] rounded-full" size={32} seed={item.review.picture_generation_details?.seed} variant={item.review.picture_generation_details?.variant} isDefault={item.review?.picture_is_default} picture={item.review.user_picture?.replace("http://", "https://")} colors={item.review?.picture_generation_details?.colors} />

                                        <span className="text-[1.1rem]">
                                            {console.log(item.review)}
                                            {item.review.user_name}
                                        </span>

                                        <span className="flex items-center gap-1 ml-auto bg-slate-700/80 rounded-lg text-slate-300 py-1 px-2">
                                            {item.review.rating} <span className="mt-[-2px] flex items-center"><ion-icon name="star"></ion-icon></span>
                                        </span>
                                    </span>

                                    <div className="flex flex-row w-full justify-between items-center gap-4">
                                        <span className="gap-3 text-[1rem] truncate font-semibold">
                                            {item.data.volumeInfo.title} by {item.data.volumeInfo.authors.join(", ")}
                                        </span>
                                    </div>

                                    {/*{item.review.review ?
                                        <div className="line-clamp-4 pl-3 bg-slate-950/20 rounded-md p-2">
                                            {item.review.review}
                                        </div>
                                    : null}*/}
                                </Link>
                            )
                        })
                    }

                    </div>


                    </div>
                </div>

                <div className="flex flex-col space-y-4 items-center col-span-5 mt-8 md:mt-0 lg:mt-0 xl:mt-0">
                    {trendingBooks.length > 0 ? trendingBooks.map((book) => {
                        //console.log(book);
                        //console.log(book.data.volumeInfo.title);
                        return (
                            <Viewer key={book?.data?.volumeInfo?.industryIdentifiers[0]?.identifier} bookData={book?.data?.volumeInfo} reviews={book?.reviews} title={book?.data?.volumeInfo?.title} description={book?.data?.volumeInfo?.description} author={book?.data?.volumeInfo?.authors} cover={book?.data?.volumeInfo?.imageLinks?.thumbnail} isbn={book.data.volumeInfo.industryIdentifiers[0].identifier} rating={book.data.volumeInfo.averageRating} readList={readList} bookmarkedList={bookmarkedList} booksReviewed={booksReviewed} updateHomeFeedStats={updateHomeFeedStats} />
                        )
                    }) :
                    <div className="w-full bg-slate-800 border-2 border-slate-700 p-3 rounded-lg text-[1.2rem] flex justify-center">
                        Loading...
                    </div>
                    }
                </div>
            </div>

            <div className="grid-new w-full mt-[0.5rem]">
                <div className="">

                    <div className="mt-[2.5rem] mb-[2.5rem] justify-center flex mx-[2rem] text-center text-wrap break-words flex-row text-[2rem] font-semibold bg-gradient-to-b from-blue-100 to-blue-300 bg-clip-text text-transparent">
                        Recommended Reads
                    </div>

                    <div className="w-[70vw] ml-auto mr-auto mb-[2rem]">

                        <RecommendedCarousel />

                    </div>

                    <div className="mt-[2.7rem] mb-[3rem] w-full justify-center flex flex-row text-[2rem] font-semibold bg-gradient-to-b from-purple-100 to-purple-300 bg-clip-text text-transparent">
                        Quick Links
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 items-center gap-[1rem] max-w-[92vw] w-fit ml-auto mr-auto">

                        <FeedStyledCard title="Discover Book Clubs" description="Discover and create book clubs with your friends and family and share your favorite books with each other." colorL="from-green-300" colorM="via-blue-500" colorR="to-purple-600" >
                            <Link to="/bookclub" className="relative inline-block p-px font-semibold leading-6 mt-3 text-white no-underline bg-slate-950 shadow-2xl cursor-pointer group rounded-xl shadow-zinc-900">
                                <span className="absolute inset-0 overflow-hidden rounded-xl">
                                    <span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                    </span>
                                </span>
                                <div className="relative flex items-center px-6 py-3 space-x-2 rounded-xl bg-gray-950/50 ring-1 ring-white/15">
                                    <span>See Your Book Clubs</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                                        data-slot="icon" className="w-6 h-6">
                                        <path fillRule="evenodd"
                                            d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-gray-400/90 to-emerald-400/0 transition-opacity duration-300 group-hover:opacity-40">
                                </span>
                            </Link>
                        </FeedStyledCard>

                        <FeedStyledCard title="Explore Bookshelves" description="Browse through our wide selection of book genres and discover your next literary adventure." colorL="from-yellow-300" colorM="via-orange-500" colorR="to-red-600">
                            <Link to="/bookshelves" className="relative inline-block p-px font-semibold leading-6 mt-3 text-white no-underline bg-slate-950 shadow-2xl cursor-pointer group rounded-xl shadow-zinc-900">
                                <span className="absolute inset-0 overflow-hidden rounded-xl">
                                    <span className="absolute inset-0 rounded-xl bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(238,125,2,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                    </span>
                                </span>
                                <div className="relative flex items-center px-6 py-3 space-x-2 rounded-xl bg-gray-950/50 ring-1 ring-white/15 ">
                                    <span>
                                        Explore Bookshelves
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                                        data-slot="icon" className="w-6 h-6">
                                        <path fillRule="evenodd"
                                            d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-gray-400/90 to-emerald-400/0 transition-opacity duration-300 group-hover:opacity-40">

                                </span>
                            </Link>
                        </FeedStyledCard>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Feed;
