import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

export default function TermsOfService() {
    return (
        <div className="min-h-screen flex flex-col grid-new">
            <Navbar />
            <div className="flex flex-col items-center justify-center flex-grow">
                <p className="text-slate-200 text-left max-w-[80vw] mx-auto p-6 bg-slate-900 shadow-lg shadow-slate-950 border border-slate-800 my-[2rem] rounded-[1rem] flex flex-col gap-[1rem]">
                    <strong className="text-2xl mb-4 block">NERDED.IO TERMS OF SERVICE</strong>
                    <span className="block mb-2">Last Updated: December 27, 2023</span>
                    <span className="block mb-4">
                        Welcome to Nerded.io! These Terms of Service ("Terms") govern your use of Nerded.io (the
                        "Platform"), so please read them carefully.
                    </span>

                    <span className="block mb-2">
                        <strong>1. Acceptance of Terms</strong> - By accessing or using Nerded.io, you agree to comply
                        with and be bound by these Terms. If you do not agree with these Terms, please do not use the
                        Platform.
                    </span>

                    <span className="block mb-2">
                        <strong>2. Privacy Policy</strong> - Our Privacy Policy outlines how we collect, use, and protect
                        your personal information. By using Nerded.io, you consent to the practices described in the Privacy
                        Policy, which can be found at <a href="https://nerded.io/legal/privacy" className="text-blue-500" target="_blank" rel="noopener noreferrer">https://nerded.io/legal/privacy</a>.
                    </span>

                    <span className="block mb-2">
                        <strong>3. User Registration</strong> - To access certain features of Nerded.io, you may be required
                        to register for an account. You agree to provide accurate and complete information during the
                        registration process and to update your information to keep it accurate.
                    </span>

                    <span className="block mb-2">
                        <strong>4. User Conduct</strong> - You agree to use Nerded.io for lawful purposes and in a way that
                        does not infringe on the rights of others or violate any applicable laws. Harassment, abusive
                        language, and any form of harmful behavior are strictly prohibited.
                    </span>

                    <span className="block mb-2">
                        <strong>5. Content</strong> - You retain ownership of the content you post on Nerded.io. By posting
                        content, you grant Nerded.io a non-exclusive, transferable, sublicensable, royalty-free license to
                        use, display, and distribute the content on the Platform.
                    </span>

                    <span className="block mb-2">
                        <strong>6. Data Collection</strong> - Nerded.io collects only the necessary data to ensure the
                        smooth functioning of the app and its core functionalities. We take the privacy and security of
                        your data seriously. For more details, refer to our Privacy Policy at <a href="https://nerded.io/legal/privacy" className="text-blue-500" target="_blank" rel="noopener noreferrer">https://nerded.io/legal/privacy</a>.
                    </span>

                    <span className="block mb-2">
                        <strong>7. Security</strong> - We employ reasonable measures to protect your data, but no method of
                        transmission over the internet or electronic storage is entirely secure. You use Nerded.io at your
                        own risk, and we cannot guarantee absolute security.
                    </span>

                    <span className="block mb-2">
                        <strong>8. Termination</strong> - We reserve the right to terminate or suspend your account and
                        access to Nerded.io at our sole discretion, without notice, for any reason, including violation of
                        these Terms.
                    </span>

                    <span className="block mb-2">
                        <strong>9. Changes to Terms</strong> - We may update these Terms from time to time. It is your
                        responsibility to review the Terms periodically. Continued use of Nerded.io after changes
                        constitutes acceptance of the modified Terms.
                    </span>

                    <span className="block mb-4">
                        <strong>10. Contact Information</strong> - For questions or concerns regarding these Terms, please
                        contact us at <a href="mailto:support@nerded.io" className="text-blue-500">support@nerded.io</a>.
                    </span>

                    <span className="block">
                        Thank you for being a part of Nerded.io! Enjoy connecting with fellow book enthusiasts and
                        exploring the world of literature.
                    </span>
                </p>
            </div>
            <Footer />
        </div>
    );
}
