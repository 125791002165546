import { useAuth } from "../../context/AuthContext";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import { Link } from "react-router-dom";

import ProfilePicture from "../../components/ProfilePicture";
import RankBadge from "../../components/RankBadge";

export default function ReviewReports() {
    const { authenticated, login, signup, logout } = useAuth();

    const reports = [
        {
            id: "efn2-4fwf3-2r3t-",
            user: {
                id: "efn2-4fwf3-2r3t-",
                username: "user1",
                rank: "member",
            },
            review: "i hte this book it is so ass 3ifewifnweiofb3iowrb3oiq b3iho",
        }
    ]

    if (!authenticated.authenticated) {
        login();
        return (
            <div>
                <Navbar />

                <div className="text-center w-full my-[2rem]">
                    Not logged in
                </div>

                <Footer />
            </div>
        )
    }

    if (authenticated.rank !== "admin") {
        return (
            <div>
                <Navbar />

                <div className="text-center w-full my-[2rem]">
                    Not an admin
                </div>

                <Footer />
            </div>
        )
    }

	return (
		<div>
			<Navbar />

			<div>
				<h1 className="text-4xl text-slate-300 mb-[2rem] mt-[2rem] font-bold mx-auto w-fit">
					Review Reports
				</h1>

                <div className="flex flex-col gap-4 w-[75vw] mx-auto items-center p-3">
                    <div className="flex flex-col gap-2 w-full overflow-y-scroll">
                        {
                            reports.map((report) => (
                                <div key={report.id} className="flex flex-col gap-2 p-4 bg-slate-900 border border-slate-700 rounded-lg">
                                    <div className="flex flex-row justify-start gap-2 py-2">
                                        <span className="text-lg text-slate-100 px-2">{report.user.username}</span>
                                        <RankBadge rank={report.user.rank} />
                                    </div>
                                    <div className="max-h-[20rem] overflow-y-scroll mb-2 text-slate-300 border-l-[3px] border-l-slate-700 pl-4">
                                        {report.review}
                                    </div>
                                    <div className="flex flex-row gap-2">
                                        <button className="px-4 py-2 bg-green-800/50 text-green-500 rounded">Okay comment</button>
                                        <button className="px-4 py-2 bg-yellow-800/50 text-yellow-500 rounded">Delete comment</button>
                                        <button className="px-4 py-2 bg-yellow-800/50 text-yellow-500 rounded">Temp ban on review</button>
                                        <button className="px-4 py-2 bg-red-800/50 text-red-500 rounded ml-[1rem]">Ban user</button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
			</div>

			<Footer />
		</div>
	);
}
