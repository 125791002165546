import { useNavigate } from "react-router-dom";

function Chip(props) {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(`/bookshelf/${props.name}`)} className="bg-slate-800 transition select-none hover:bg-slate-700/70 text-slate-50 text-[1.2rem] px-[1rem] cursor-pointer box-border rounded-xl p-2 min-w-fit max-h-[2.3rem] flex items-center justify-center">{props.name}</div>
    )
}

export default Chip;